import React, { useState, useEffect } from 'react'
import { Select } from 'antd'

import ISOLangs from '../helper/isoLangs.json'

const LanguageSelect = ((props) => {
    let { className, handleChange, value } = props;

    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        let arr = []
        let obj = {}
        ISOLangs?.map((option) => {
            obj['label'] = `${option?.name} - ${option?.nativeName}`
            obj['value'] = option?.code
            arr.push(obj)
            obj = {}
        })
        setLanguages(arr)
    }, [])

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Select
            showSearch
            filterOption={filterOption}
            className={`custom-select ${className}`}
            placeholder="Select language"
            value={value}
            onChange={handleChange}
            options={languages}
        />
    )
})

export default LanguageSelect
