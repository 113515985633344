import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Select } from 'antd'

import Client from './components/client'
import ParentClient from './components/parent-client'
import Entity from './components/entity'
import Individual from './components/individual'
import CorporateEntity from './components/corporate-entity'

export default function Creation() {

    const MFTOptions = [
        {
            value: 'client',
            label: 'Client'
        },
        {
            value: 'parent-client',
            label: 'Parent Client'
        },
        {
            value: 'entity',
            label: 'Entity'
        },
        {
            value: 'individual',
            label: 'Individual'
        },
        {
            value: 'corporate-entity',
            label: 'Corporate Entity'
        },
    ]

    const [selectedMFT, setSelectedMFT] = useState(null)

    const getMFT = (value) => {
        console.log(value);
        setSelectedMFT(value)
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row mb-4">
                <h1 className='page-title mb-3 mb-md-0'>Master File Creation</h1>
                <Link to={'/app/pre-incorp-resolution'} className='primary-btn'>Create</Link>
            </div>
            <div className="custom-form">
                <h2 className='primary-title mb-4'>Master File Type</h2>
                <div className="mb-4">
                    <label htmlFor="" className="me-3 mb-1">Master File Type</label>
                    <Select
                        className="custom-select mw-300"
                        placeholder="Select any"
                        onChange={getMFT}
                        options={MFTOptions}
                    />
                </div>
            </div>
            {selectedMFT == 'client' &&
                <Client />
            }
            {selectedMFT == 'parent-client' &&
                <ParentClient />
            }
            {selectedMFT == 'entity' &&
                <Entity />
            }
            {selectedMFT == 'individual' &&
                <Individual />
            }
            {selectedMFT == 'corporate-entity' &&
                <CorporateEntity />
            }
        </div>
    )
}
