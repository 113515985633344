import React from 'react'
import { Select, DatePicker } from 'antd'

import Jurisdiction from '../../../components/Jurisdiction';

export default function Entity() {
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <div className="custom-form">
            <h2 className='sub-title mb-4'>Master File Creation: Entity</h2>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Entity Unicode</label>
                    <input type="text" className='w-100' placeholder="Enter entity unicode" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Entity Name</label>
                    <input type="text" className='w-100' placeholder="Enter entity name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Entity Type</label>
                    <Select
                        className="custom-select w-100"
                        placeholder="Select entity type"
                        options={[
                            {
                                value: 'BVIBC',
                                label: 'BVIBC'
                            },
                            {
                                value: 'trust',
                                label: 'Trust'
                            },
                            {
                                value: 'foundation',
                                label: 'Foundation'
                            },
                            {
                                value: 'general-partnership',
                                label: 'General Partnership'
                            },
                            {
                                value: 'limited-partnership',
                                label: 'Limited Partnership'
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Incorporation Date</label>
                    <DatePicker
                        className="custom-datepicker w-100"
                        placeholder="Enter Date (DD/MM/YYYY)"
                        format={'DD/MM/YYYY'}
                    // onChange={}
                    />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Incorporation Number</label>
                    <input type="text" className='w-100' placeholder="Enter incorporation number" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Country of Incorporation</label>
                    <Jurisdiction
                        className="w-100"
                        placeholder="Select country of birth"
                        value={'BVI'}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Jurisdiction</label>
                    <Jurisdiction className={'w-100'} handleChange={handleChange} />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Related Entity Code</label>
                    <Select
                        className="custom-select w-100"
                        placeholder="Select entity type"
                        options={[
                            {
                                value: 'E203',
                                label: 'E203'
                            },
                            {
                                value: 'E204',
                                label: 'E204'
                            },
                            {
                                value: 'E205',
                                label: 'E205'
                            },
                        ]}
                    />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Nature of Business</label>
                    <input type="text" className='w-100' placeholder="Enter nature of business" />
                </div>

            </div>
            {/* <div className="d-flex gap-3">
                <button className='primary-btn'>Analyze</button>
                <button className='outline-btn'>Web Search</button>
            </div> */}
        </div>
    )
}