import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Individual from './components/individual'
import CorporateKYC from './components/corporate-kyc'
import Trusts from './components/trusts'
import Foundations from './components/foundations'
import Others from './components/others'

export default function RelationshipManager() {
    const [activeTab, setActiveTab] = useState('individual')

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
                <h1 className='page-title mb-3 mb-md-0'>End User / Relationship Manager</h1>
                <Link to={'/app/active-requests'} className='primary-btn'>Submit</Link>
            </div>
            <h2 className='primary-title mb-4'>Documents</h2>
            <div className="custom-form">
                <div className="row">
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">Inaugural Resolution</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">Appointment of First Director</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">Resolution of Share Allotment</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">Corporate Seal Draft</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">Directors Consent Letter</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">Customized M&AA</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">UBO Source of Funds Declaration</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">Incorporation Application Form</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">Transfer-In Form for BVIBC</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="inaugural-resolution" className="me-3">Written Confirmations-Virtual Assets</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="inaugural-resolution" id="inaugural-resolution" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-wrapper my-5">
                <button className={`tab-btn ${activeTab == 'individual' ? 'active' : ''}`} onClick={() => setActiveTab('individual')}>Individual</button>
                <button className={`tab-btn ${activeTab == 'corporateKYC' ? 'active' : ''}`} onClick={() => setActiveTab('corporateKYC')}>Corporate KYC</button>
                <button className={`tab-btn ${activeTab == 'trusts' ? 'active' : ''}`} onClick={() => setActiveTab('trusts')}>Trusts</button>
                <button className={`tab-btn ${activeTab == 'foundations' ? 'active' : ''}`} onClick={() => setActiveTab('foundations')}>Foundations</button>
                <button className={`tab-btn ${activeTab == 'others' ? 'active' : ''}`} onClick={() => setActiveTab('others')}>Others</button>
            </div>

            {activeTab == 'individual' &&
                <Individual />
            }
            {activeTab == 'corporateKYC' &&
                <CorporateKYC />
            }
            {activeTab == 'trusts' &&
                <Trusts />
            }
            {activeTab == 'foundations' &&
                <Foundations />
            }
            {activeTab == 'others' &&
                <Others />
            }
        </div>
    )
}
