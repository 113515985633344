import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Popover, Modal } from 'antd';
import { NavLink } from "react-router-dom";

import Logo from '../../assets/img/logo-icon.png'

import { checkLogged } from '../../store/auth/authSlice'
import { sendNotify } from '../../helper'
import routes from '../../helper/routes';

const { confirm } = Modal;

export default function Sidebar() {
  const dispatch = useDispatch()
  let details = useSelector((state) => state.auth.userDetails)

  const confirmLogout = () => {
    confirm({
      title: <h5>Confirm Logout</h5>,
      content: <p>Are you sure you want to log out?</p>,
      className: 'custom-confirm',
      centered: true,
      okText: 'Logout',
      onOk() {
        logout();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const logout = () => {
    sendNotify('success', "Logout successfully!")
    localStorage.clear(process.env.REACT_APP_JWT_TOKEN)
    dispatch(checkLogged())
  }

  return (
    <div className={`sidebar`}>
      <div>
        <img src={Logo} className='logo' alt="North Lark" />
        <ul className='sidebar-list'>
          {routes.filter(r => r.showInMenu).map(route => {
            if (route.permissions.find(r => r === details?.role)) {
              return (
                <li key={route.path}>
                  <Popover placement="left" className='sidebar-popover' title={route.name}>
                    <NavLink to={route.path}>
                      <i className={`fal ${route.icon}`}></i>
                    </NavLink>
                  </Popover>
                </li>
              )
            }
          })}
        </ul>
      </div>
      <ul>
        <li>
          <NavLink to={'/help'}>
            <i className="fal fa-life-ring"></i>
          </NavLink>
        </li>
        <li>
          <NavLink to={'/help'}>
            <i className="fal fa-cog"></i>
          </NavLink>
        </li>
        <li className='profile'>
          <Popover placement="right" title={''} content={
            <div className='profile-wrapper'>
              <div>
                <h6>{details?.name}</h6>
                <p>{details?.role}</p>
              </div>
              <button className="nav-btn-secondary ms-md-3" onClick={confirmLogout} title="Logout">
                <i className="far fa-power-off"></i>
              </button>
            </div>
          }>
            <img src="https://www.w3schools.com/w3images/avatar3.png" alt="" />
          </Popover>
        </li>
      </ul>
    </div>
  )
}
