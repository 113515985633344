import React from 'react'
import { Select } from 'antd';
import Jurisdiction from '../../components/Jurisdiction';

export default function Client() {

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-5">
                <h1 className='page-title mb-3 mb-md-0'>Client</h1>
                <div className="d-flex gap-3">
                    <button className='outline-btn'><i className="fal fa-cloud-upload"></i> Import</button>
                    <button className='primary-btn'><i className="fal fa-plus"></i> Add Client</button>
                </div>
            </div>
            <div className="custom-form">
                <h6 className='sub-title mb-4'>Client Information</h6>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Client Name</label>
                        <input type="text" className='w-100' placeholder="Enter client name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Client Code</label>
                        <input type="text" className='w-100' placeholder="Enter client code" />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Parent Client Name</label>
                        <input type="text" className='w-100' placeholder="Enter parent client name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Parent Client Code</label>
                        <input type="text" className='w-100' placeholder="Enter parent client code" />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Client Group</label>
                        <input type="text" className='w-100' placeholder="Enter client group" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Jurisdiction</label>
                        <Jurisdiction className={'w-100'} handleChange={handleChange} />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Billing Category</label>
                        <input type="text" className='w-100' placeholder="Enter billing category" />
                    </div>
                </div>
                <h6 className='sub-title mt-5 mt-0 mb-4'>Administrator</h6>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Administrator Name</label>
                        <input type="text" className='w-100' placeholder="Enter administrator name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Administrator Code</label>
                        <input type="text" className='w-100' placeholder="Enter administrator code" />
                    </div>
                </div>
                <h6 className='sub-title mt-5 mt-0 mb-4'>Admin Manager</h6>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Admin Manager Name</label>
                        <input type="text" className='w-100' placeholder="Enter admin manager name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Admin Manager Code</label>
                        <input type="text" className='w-100' placeholder="Enter admin manager code" />
                    </div>
                </div>
                <h6 className='sub-title mt-5 mt-0 mb-4'>Related Entities</h6>
                <div className="row mb-5 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Entity Name</label>
                        <input type="text" className='w-100' placeholder="Enter entity name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Entity Type</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select entity type"
                            options={[
                                {
                                    value: 'BVIBC',
                                    label: 'BVIBC'
                                },
                                {
                                    value: 'trust',
                                    label: 'Trust'
                                },
                                {
                                    value: 'foundation',
                                    label: 'Foundation'
                                },
                                {
                                    value: 'general-partnership',
                                    label: 'General Partnership'
                                },
                                {
                                    value: 'limited-partnership',
                                    label: 'Limited Partnership'
                                },
                            ]}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Entity Code</label>
                        <input type="text" className='w-100' placeholder="Enter entity code" />
                    </div>
                </div>
                <div className="row mb-5 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Entity Name</label>
                        <input type="text" className='w-100' placeholder="Enter entity name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Entity Type</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select entity type"
                            options={[
                                {
                                    value: 'BVIBC',
                                    label: 'BVIBC'
                                },
                                {
                                    value: 'trust',
                                    label: 'Trust'
                                },
                                {
                                    value: 'foundation',
                                    label: 'Foundation'
                                },
                                {
                                    value: 'general-partnership',
                                    label: 'General Partnership'
                                },
                                {
                                    value: 'limited-partnership',
                                    label: 'Limited Partnership'
                                },
                            ]}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Entity Code</label>
                        <input type="text" className='w-100' placeholder="Enter entity code" />
                    </div>
                </div>
                <div className="row mb-5 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Entity Name</label>
                        <input type="text" className='w-100' placeholder="Enter entity name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Entity Type</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select entity type"
                            options={[
                                {
                                    value: 'BVIBC',
                                    label: 'BVIBC'
                                },
                                {
                                    value: 'trust',
                                    label: 'Trust'
                                },
                                {
                                    value: 'foundation',
                                    label: 'Foundation'
                                },
                                {
                                    value: 'general-partnership',
                                    label: 'General Partnership'
                                },
                                {
                                    value: 'limited-partnership',
                                    label: 'Limited Partnership'
                                },
                            ]}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Entity Code</label>
                        <input type="text" className='w-100' placeholder="Enter entity code" />
                    </div>
                </div>
                <button className='primary-btn'><i className="fal fa-plus"></i> Add New Related Entity</button>
            </div>
        </div>
    )
}
