import React from 'react'
import { Select } from 'antd'
import { Link } from 'react-router-dom'

export default function NewIncorporation() {



    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4 mb-md-5">
                <h1 className='page-title mb-3 mb-md-0'>New Incorporation</h1>
                <div className="d-flex gap-3 flex-column flex-md-row">
                    <button className='outline-btn'><i className="fal fa-plus"></i> Add New Upload Pack</button>
                    <Link to={'/app/incorporation-confirmation'} className='primary-btn'>Store and Submit</Link>
                </div>
            </div>
            <div className="custom-form">
                <div className="row mb-4 mb-md-5">
                    <div className="col-md-3 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Entity Name</label>
                            <input type="text" className='mw-300' placeholder="Enter entity name" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Entity Type</label>
                            <Select
                                className="custom-select mw-300"
                                placeholder="Select entity type"
                                options={[
                                    {
                                        value: 'BVIBC',
                                        label: 'BVIBC'
                                    },
                                    {
                                        value: 'trust',
                                        label: 'Trust'
                                    },
                                    {
                                        value: 'foundation',
                                        label: 'Foundation'
                                    },
                                    {
                                        value: 'general-partnership',
                                        label: 'General Partnership'
                                    },
                                    {
                                        value: 'limited-partnership',
                                        label: 'Limited Partnership'
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Entity Code</label>
                            <input type="text" className='mw-300' placeholder="Enter entity code" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Client Name</label>
                            <input type="text" className='mw-300' placeholder="Enter client name" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Client Code</label>
                            <input type="text" className='mw-300' placeholder="Enter client code" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Parent Client Name</label>
                            <input type="text" className='mw-300' placeholder="Enter parent client name" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Parent Client Code</label>
                            <input type="text" className='mw-300' placeholder="Enter parent client code" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">RM Name</label>
                            <input type="text" className='mw-300' placeholder="Enter rm name" />
                        </div>
                    </div>
                    <div className="col-md-3 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">RM Code</label>
                            <input type="text" className='mw-300' placeholder="Enter rm code" />
                        </div>
                    </div>
                </div>
                <h4 className='primary-title mb-4'>Upload Pack</h4>
                <div className="row">
                    <div className='col-md-3 mb-4'>
                        <label htmlFor="" className="me-3">01</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="" id="" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-4'>
                        <label htmlFor="" className="me-3">02</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="" id="" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-4'>
                        <label htmlFor="" className="me-3">03</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="" id="" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-4'>
                        <label htmlFor="" className="me-3">04</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="" id="" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-3 mb-4'>
                        <label htmlFor="" className="me-3">01</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="" id="" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-4'>
                        <label htmlFor="" className="me-3">02</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="" id="" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-4'>
                        <label htmlFor="" className="me-3">03</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="" id="" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3 mb-4'>
                        <label htmlFor="" className="me-3">04</label>
                        <div className="uploader">
                            <div className="upload-wrapper">
                                <input type="file" name="" id="" />
                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
