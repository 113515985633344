import React from 'react'
import { Link } from 'react-router-dom'

export default function AdditionalScreens() {
    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-5">
                <h1 className='page-title mb-3 mb-md-0'>Additional Screens</h1>
                <Link to={'/app/'} className='primary-btn'>Initiate Request</Link>
            </div>
            <div className='d-flex flex-column gap-4'>
                <Link to={'/app/additional-screens/cdd'} className='primary-btn'>CDD Screen</Link>
                <Link to={'/app/additional-screens/client'} className='primary-btn'>Client Screen</Link>
                <Link to={'/app/additional-screens/shareholder'} className='primary-btn'>Shareholder Screen</Link>
                <Link to={'/app/additional-screens/director'} className='primary-btn'>Director Screen</Link>
                <Link to={'/app/additional-screens/crypto'} className='primary-btn'>Crypto TM Screen</Link>
            </div>
        </div>
    )
}
