import React from 'react'
import { Select } from 'antd';

export default function Trusts() {

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    let arr = [1, 2, 3, 4, 5, 6]

    return (
        <div className='custom-form'>
            <h6 className='primary-title mb-4'>Trusts</h6>
            <div className="info-box mb-4">
                <h6>Where the beneficial owner, director, shareholder or officer is a Trust, the following will
                    need to be provided in support of the duly completed application form:</h6>
                <ol>
                    <li>
                        The name of the trust;
                    </li>
                    <li>The date and country of establishment of the trust;</li>
                    <li>Where there is an agent acting for the trust, the name and address of the agent;</li>
                    <li>The nature and purpose of the trust;</li>
                    <li>Identifying information in relation to any Person appointed as a trustee, settlor (grantor) or
                        protector of the trust. (See A. Individuals and B. Corporate)</li>
                    <li>Identity of beneficiaries of the trust. (See A. Individuals and B. Corporate)</li>
                </ol>
                <p>Items 1-4 would need to be verified. A signed letter from the professional trustee duly notarised
                    would suffice as acceptable verification for these items.</p>
            </div>
            <div className="row">
                {arr.map(item => {
                    return (
                        <div className="col-md-3 mb-4">
                            <label htmlFor="passport-id" className="me-3">Trust Pack 0{item}</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="passport-id" id="passport-id" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
