import React from 'react'
import { Select } from 'antd';

export default function Foundations() {

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    let arr = [1, 2, 3, 4, 5, 6, 7]

    return (
        <div className='custom-form'>
            <h6 className='primary-title mb-4'>Foundations</h6>
            <div className="info-box mb-4">
                <h6>Where the beneficial owner, director, shareholder or officer is a Foundation, the following
                    will need to be provided in support of the duly completed application form:</h6>
                <ol>
                    <li>The full name of the foundation</li>
                    <li>The official registration or other identification number of the foundation</li>
                    <li>The date and place of formation and registration of the foundation</li>
                    <li>The physical and mailing addresses of the foundation’s office</li>
                    <li>The physical and mailing addresses of the authorized representative of the foundation to
                        whom correspondence may be sent, if different from (d) above</li>
                    <li>The foundations principal place of business and the purpose of the foundation, the source of
                        the foundations’ funding</li>
                    <li>The identity of each individual acting on behalf of the foundation, including each individual on
                        the Board of Trustees, Committee Members, Executive Director or President of the
                        Foundation</li>
                </ol>
            </div>
            <div className="row">
                {arr.map(item => {
                    return (
                        <div className="col-md-3 mb-4">
                            <label htmlFor="passport-id" className="me-3">Foundation Pack 0{item}</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="passport-id" id="passport-id" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
