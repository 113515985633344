import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import countrySlice from "./country/countrySlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    country: countrySlice,
  },
});
