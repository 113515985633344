import React from 'react'
import { Select, DatePicker } from 'antd'

import Jurisdiction from '../../../components/Jurisdiction'

export default function Individual() {

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <div className="custom-form">
            <h2 className='sub-title mb-4'>Master File Creation: Individual</h2>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Individual Unicode</label>
                    <input type="text" className='w-100' placeholder="Enter individual unicode" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Title</label>
                    <Select
                        className="custom-select w-100"
                        placeholder="Select title"
                        // onChange={}
                        options={[
                            {
                                value: 'mr',
                                label: 'Mr'
                            },
                            {
                                value: 'mrs',
                                label: 'Mrs'
                            },
                            {
                                value: 'miss',
                                label: 'Miss'
                            },
                            {
                                value: 'ms',
                                label: 'Ms'
                            },
                            {
                                value: 'dr',
                                label: 'Dr'
                            },
                            {
                                value: 'rev',
                                label: 'Rev'
                            },
                        ]}
                    />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Individual Role</label>
                    <Select
                        className="custom-select w-100 multi-select"
                        placeholder="Select title"
                        mode="multiple"
                        showSearch
                        allowClear
                        // onChange={}
                        options={[
                            {
                                value: 'director',
                                label: 'Director'
                            },
                            {
                                value: 'shareholder',
                                label: 'Shareholder'
                            },
                            {
                                value: 'ubo',
                                label: 'UBO'
                            },
                            {
                                value: 'poc',
                                label: 'POC'
                            },
                            {
                                value: 'trustee',
                                label: 'Trustee'
                            },
                            {
                                value: 'settlor',
                                label: 'Settlor'
                            },
                            {
                                value: 'beneficiary',
                                label: 'Beneficiary'
                            },
                            {
                                value: 'frHolder',
                                label: 'FR Holder'
                            },
                            {
                                value: 'reserveDirector',
                                label: 'Reserve Director'
                            },
                            {
                                value: 'nomineeShareholder',
                                label: 'Nominee Shareholder'
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">First Name</label>
                    <input type="text" className='w-100' placeholder="Enter first name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Middle Name</label>
                    <input type="text" className='w-100' placeholder="Enter middle name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Last Name</label>
                    <input type="text" className='w-100' placeholder="Enter last name" />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Date of Birth</label>
                    <DatePicker
                        className="custom-datepicker w-100"
                        placeholder="Enter Date (DD/MM/YYYY)"
                        format={'DD/MM/YYYY'}
                    // onChange={}
                    />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Country of Birth</label>
                    <Jurisdiction
                        className="w-100"
                        placeholder="Select country of birth"
                    />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Nationality</label>
                    <Jurisdiction className={'w-100'} handleChange={handleChange} placeholder="Select nationality" />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Passport Number</label>
                    <input type="text" className='w-100' placeholder="Enter passport number" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Expiry Date</label>
                    <DatePicker
                        className="custom-datepicker w-100"
                        placeholder="Enter Date (DD/MM/YYYY)"
                        format={'DD/MM/YYYY'}
                    // onChange={}
                    />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">ID Number</label>
                    <input type="text" className='w-100' placeholder="Enter ID number" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Expiry Date</label>
                    <DatePicker
                        className="custom-datepicker w-100"
                        placeholder="Enter Date (DD/MM/YYYY)"
                        format={'DD/MM/YYYY'}
                    // onChange={}
                    />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Tax Info Number</label>
                    <input type="text" className='w-100' placeholder="Enter tax info number" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="me-3 mb-1">Risk Rating</label>
                    <Select
                        className="custom-select w-100"
                        placeholder="Select risk level"
                        defaultValue={'low'}
                        onChange={handleChange}
                        options={[
                            {
                                value: 'low',
                                label: 'Low'
                            },
                            {
                                value: 'medium',
                                label: 'Medium'
                            },
                            {
                                value: 'high',
                                label: 'High'
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="form-row flex-column flex-md-row align-items-start align-items-md-center mb-3 mb-md-4">
                        <label htmlFor="" className="me-3">Address Line 01</label>
                        <input type="text" placeholder="Enter address" />
                    </div>
                    <div className="form-row flex-column flex-md-row align-items-start align-items-md-center mb-3 mb-md-4">
                        <label htmlFor="" className="me-3">Address Line 02</label>
                        <input type="text" placeholder="Enter address" />
                    </div>
                    <div className="form-row flex-column flex-md-row align-items-start align-items-md-center mb-3 mb-md-4">
                        <label htmlFor="" className="me-3">Address Line 03</label>
                        <input type="text" placeholder="Enter address" />
                    </div>
                    <div className="d-flex gap-3">
                        <button className='primary-btn'>Analyze</button>
                        <button className='outline-btn'>Web Search</button>
                        <button className='outline-btn'>Analysis Result</button>
                        <button className='outline-btn'>Store Result</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
