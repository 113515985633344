import React from 'react'
import { Select, DatePicker } from 'antd'

import Jurisdiction from '../../../components/Jurisdiction';

export default function Entity() {
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <div className="custom-form">
            <h2 className='sub-title mb-4'>Master File Creation: Entity</h2>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Entity Name</label>
                    <input type="text" className='w-100' placeholder="Enter entity name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Entity Type</label>
                    <Select
                        className="custom-select w-100"
                        placeholder="Select entity type"
                        options={[
                            {
                                value: 'BVIBC',
                                label: 'BVIBC'
                            },
                            {
                                value: 'trust',
                                label: 'Trust'
                            },
                            {
                                value: 'foundation',
                                label: 'Foundation'
                            },
                            {
                                value: 'general-partnership',
                                label: 'General Partnership'
                            },
                            {
                                value: 'limited-partnership',
                                label: 'Limited Partnership'
                            },
                        ]}
                    />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Entity Code</label>
                    <input type="text" className='w-100' placeholder="Enter entity code" />
                </div>
                <div className="col-md-3">
                    <label htmlFor="" className="me-3 mb-1">Entity Status:</label>
                    <Select
                        className="custom-select w-100"
                        placeholder="Select"
                        onChange={handleChange}
                        options={[
                            {
                                value: 'active',
                                label: 'Active'
                            },
                            {
                                value: 'inactive',
                                label: 'Inactive'
                            },
                            {
                                value: 'closing',
                                label: 'Closing'
                            },
                            {
                                value: 'closed',
                                label: 'Closed'
                            },
                            {
                                value: 'transferringOut',
                                label: 'Transferring Out'
                            },
                            {
                                value: 'prospect',
                                label: 'Prospect'
                            },
                            {
                                value: 'transferringIn',
                                label: 'Transferring In'
                            },
                            {
                                value: 'liquidated',
                                label: 'Liquidated'
                            },
                            {
                                value: 'struckOff',
                                label: 'Struck Off'
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Client Name</label>
                    <input type="text" className='w-100' placeholder="Enter client name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Client Code</label>
                    <input type="text" className='w-100' placeholder="Enter client code" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Jurisdiction</label>
                    <Jurisdiction className={'w-100'} handleChange={handleChange} />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Incorporation Date</label>
                    <DatePicker
                        className="custom-datepicker w-100"
                        placeholder="Enter Date (DD/MM/YYYY)"
                        format={'DD/MM/YYYY'}
                    // onChange={}
                    />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Incorporation Number</label>
                    <input type="text" className='w-100' placeholder="Enter incorporation number" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Country of Incorporation</label>
                    <Jurisdiction
                        className="w-100"
                        placeholder="Select country of incorporation"
                        value={'BVI'}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Relationship Manager</label>
                    <input type="text" className='w-100' placeholder="Enter relationship manager name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Administrator</label>
                    <input type="text" className='w-100' placeholder="Enter administrator name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Admin Manager</label>
                    <input type="text" className='w-100' placeholder="Enter admin manager name" />
                </div>
            </div>
            {/* <div className="d-flex gap-3">
                <button className='primary-btn'>Analyze</button>
                <button className='outline-btn'>Web Search</button>
            </div> */}
        </div>
    )
}
