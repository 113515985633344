import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal, Table } from 'antd'

export default function OnboardingTeam() {
    const [rejectModal, setRejectModal] = useState(false)

    const columns = [
        {
            title: 'Requestor Ref',
            dataIndex: 'ref',
            sorter: (a, b) => a.ref - b.ref,
        },
        {
            title: 'Request Date',
            dataIndex: 'date',
            sorter: (a, b) => a.date - b.date,
        },
        {
            title: 'Service Fee Category',
            dataIndex: 'serviceFee',
            sorter: (a, b) => a.date - b.date,
        },
        {
            title: 'Entity Type',
            dataIndex: 'entityType',
            sorter: (a, b) => a.entityType - b.entityType,
        },
    ];

    const data = [
        {
            key: '1',
            id: '1',
            ref: 'John Doe',
            date: '20/10/2023',
            serviceFee: 'OCR',
            entityType: 'Trust',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    const showRejectModal = () => {
        setRejectModal(true)
    }
    const handleOk = () => {
        setRejectModal(false);
    };
    const handleCancel = () => {
        setRejectModal(false);
    };

    return (
        <div>
            <h1 className='page-title mb-4'>New Incorporation Name Check</h1>
            <h2 className='primary-title mb-4'>Name Check Requests</h2>

            {/* <Table className='custom-table' rowSelection={{ type: 'checkbox', ...rowSelection }} columns={columns} dataSource={data} /> */}

            <div className="d-flex gap-md-5 flex-column flex-md-row">
                <p className='head-label'><b>Requestor Ref:</b> John Doe</p>
                <p className='head-label'><b>Request Date:</b> 20/10/2023</p>
                <p className='head-label'><b>Service Fee Category:</b> OCR</p>
                <p className='head-label'><b>Entity Type:</b> Trust</p>
            </div>

            <div className="custom-form">
                <h2 className='sub-title my-4'>Proposed Name</h2>
                <div className="form-row mb-4">
                    <label htmlFor="" className="me-3">Option 01</label>
                    <input type="text" placeholder="Enter Name" defaultValue={'Sample Name 01'} />
                </div>
                <div className="form-row mb-4">
                    <label htmlFor="" className="me-3">Option 02</label>
                    <input type="text" placeholder="Enter Name" defaultValue={'Sample Name 02'} />
                </div>
                <div className="form-row mb-4">
                    <label htmlFor="" className="me-3">Option 03</label>
                    <input type="text" placeholder="Enter Name" defaultValue={'Sample Name 03'} />
                </div>
                <div className="d-flex gap-3">
                    <button className='primary-btn'>Submit to Registry</button>
                    <button className='outline-btn'>Confirmation from Registry</button>
                </div>

                <h2 className='primary-title mt-5 mb-4'>Name Availability</h2>
                <div className="form-row mb-4">
                    <label htmlFor="" className="me-3">Option 01</label>
                    <input type="text" placeholder="Enter Name" />
                </div>
                <div className="form-row mb-4">
                    <label htmlFor="" className="me-3">Option 02</label>
                    <input type="text" placeholder="Enter Name" />
                </div>
                <div className="form-row mb-4">
                    <label htmlFor="" className="me-3">Option 03</label>
                    <input type="text" placeholder="Enter Name" />
                </div>
                <div className='d-flex gap-3'>
                    <Link to={'/app/master-file-creation'} className='primary-btn'>Confirm Availability</Link>
                    <button className='outline-btn red-btn' onClick={showRejectModal}>Reject</button>
                </div>
            </div>
            <Modal title={"Reject"} className='custom-modal' open={rejectModal} onOk={handleOk} okText="Reject" onCancel={handleCancel}>
                <div className="custom-form">
                    <label htmlFor="" className="me-3">Reason to Reject</label>
                    <textarea name="" id="" rows="5" className='w-100'></textarea>
                </div>
            </Modal>
        </div>
    )
}
