import React from 'react'
import { Table, Tag, Select } from 'antd';
import { Link } from 'react-router-dom';

export default function ReviewListing() {
    const columns = [
        {
            title: 'Task Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Task Type',
            dataIndex: 'type',
            sorter: (a, b) => a.type - b.type,
        },
        {
            title: 'Approval Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (_, { status }) => (
                <Select
                    className="custom-select"
                    placeholder="Select type"
                    value={status}
                    style={{
                        width: 160
                    }}
                    options={[
                        {
                            value: 'in-progress',
                            label: 'In Progress'
                        },
                        {
                            value: 'approved',
                            label: 'Approved'
                        },
                        {
                            value: 'rejected',
                            label: 'Rejected'
                        },
                        {
                            value: 'pending',
                            label: 'Pending'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Created Date and Time',
            dataIndex: 'createdAt',
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            sorter: (a, b) => a.createdBy - b.createdBy,
        },
        {
            title: 'Entity Name',
            dataIndex: 'entityName',
            key: 'entityName',
            sorter: (a, b) => a.entityName - b.entityName,
        },
        {
            title: 'Entity Code',
            dataIndex: 'entityCode',
            key: 'entityCode',
            sorter: (a, b) => a.entityCode - b.entityCode,
        },
        {
            title: 'Requestor ID',
            dataIndex: 'requestorID',
            sorter: (a, b) => a.requestorID - b.requestorID,
        },
        {
            title: 'Review Status',
            dataIndex: 'reviewStatus',
            sorter: (a, b) => a.reviewStatus - b.reviewStatus,
            render: (_, { reviewStatus }) => (
                <Select
                    className="custom-select"
                    placeholder="Select type"
                    value={reviewStatus}
                    style={{
                        width: 160
                    }}
                    options={[
                        {
                            value: 'in-progress',
                            label: 'In Progress'
                        },
                        {
                            value: 'completed',
                            label: 'Completed'
                        },
                        {
                            value: 'pending-info',
                            label: 'Pending Info'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Processor',
            dataIndex: 'processor',
            sorter: (a, b) => a.processor - b.processor,
        },
        {
            title: 'RAF',
            dataIndex: 'ref',
            sorter: (a, b) => a.ref - b.ref,
            render: (_, data) => (
                <div className="table-upload">
                    <label htmlFor="doc" className='outline-btn py-2'>Upload</label>
                    <input type="file" name="doc" id="doc" />
                </div>
            ),
        },
        {
            title: 'Actions',
            fixed: 'right',
            width: 180,
            render: (_, { id }) => (
                <Link to={``} className='action-btn'>View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i></Link>
            ),
        },
    ];
    const data = [
        {
            key: '1',
            id: '1',
            name: 'Task #1',
            type: 'Active',
            status: 'approved',
            createdAt: '07/11/2023 04:56PM',
            createdBy: 'John Doe',
            entityName: 'Trust',
            entityCode: '9012',
            requestorID: '2343',
            reviewStatus: 'completed',
            processor: 'Hari'
        },
        {
            key: '2',
            id: '2',
            name: 'Task #2',
            type: 'Active',
            status: 'pending',
            createdAt: '07/11/2023 04:56PM',
            createdBy: 'John Doe',
            entityName: 'Trust',
            entityCode: '9012',
            requestorID: '2343',
            reviewStatus: 'pending-info',
            processor: 'Hari'
        },
        {
            key: '3',
            id: '3',
            name: 'Task #3',
            type: 'Active',
            status: 'in-progress',
            createdAt: '07/11/2023 04:56PM',
            createdBy: 'John Doe',
            entityName: 'Trust',
            entityCode: '9012',
            requestorID: '2343',
            reviewStatus: 'in-progress',
            processor: 'Hari'
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-5">
                <h1 className='page-title mb-3 mb-md-0'>MLRO Sign Off Dashboard</h1>
                <Link to={'/app/mlro'} className='primary-btn'>Send to MLRO and Trigger RM</Link>
            </div>
            <div className="mb-md-3 d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center">
                <h2 className='primary-title mb-3 mb-md-0'>MLRO Approval Status</h2>
                <div className="d-flex gap-3 flex-column flex-md-row">
                    <button className='outline-btn'><i className="far fa-arrow-to-bottom"></i> Download Selected</button>
                    <button className='outline-btn'><i className="far fa-paper-plane"></i> Send for approval</button>
                </div>
            </div>
            <Table className='custom-table' rowSelection={{ type: 'checkbox', ...rowSelection }} columns={columns} dataSource={data} scroll={{ x: 2500 }} />
        </div>
    )
}
