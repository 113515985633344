import React from 'react'
import { Link } from 'react-router-dom'
import { Select, DatePicker } from 'antd'
import dayjs from 'dayjs'

import Jurisdiction from '../../components/Jurisdiction';

export default function CDD() {
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-5">
                <h1 className='page-title mb-3 mb-md-0'>CDD</h1>
                <Link to={'/app/incorporation-pack'} className='primary-btn'>Export</Link>
            </div>
            <h4 className='primary-title mb-3 mb-md-4'>CDD Information</h4>
            <div className="custom-form">
                <div className='mb-3'>
                    <label htmlFor="" className="me-3">Source of Wealth</label>
                    <textarea name="" id="" className='w-100' placeholder='Enter a description...' rows="5"></textarea>
                </div>
                <div className='mb-3'>
                    <label htmlFor="" className="me-3">Source of Funds</label>
                    <textarea name="" id="" className='w-100' placeholder='Enter a description...' rows="5"></textarea>
                </div>
                <div className='mb-3'>
                    <label htmlFor="" className="me-3">Purpose of Business</label>
                    <textarea name="" id="" className='w-100' placeholder='Enter a description...' rows="5"></textarea>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Entity Name:</label>
                        <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Sample Entity'} />
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Entity Type:</label>
                        <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Trust'} />
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Entity Code:</label>
                        <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'E001'} />
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Entity Status:</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select"
                            defaultValue={'active'}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'active',
                                    label: 'Active'
                                },
                                {
                                    value: 'inactive',
                                    label: 'Inactive'
                                },
                                {
                                    value: 'closing',
                                    label: 'Closing'
                                },
                                {
                                    value: 'closed',
                                    label: 'Closed'
                                },

                                {
                                    value: 'transferringOut',
                                    label: 'Transferring Out'
                                },
                                {
                                    value: 'prospect',
                                    label: 'Prospect'
                                },
                                {
                                    value: 'transferringIn',
                                    label: 'Transferring In'
                                },
                                {
                                    value: 'liquidated',
                                    label: 'Liquidated'
                                },
                                {
                                    value: 'struckOff',
                                    label: 'Struck Off'
                                },
                            ]}
                        />
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Client Name:</label>
                        <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'John Doe'} />
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Client Code:</label>
                        <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'C040'} />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="me-3 mb-1">Geography of Business</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select industry"
                            onChange={handleChange}
                            options={[]}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="me-3 mb-1">Industry</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select industry"
                            onChange={handleChange}
                            options={[]}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="me-3 mb-1">Industrial Risk</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select risk level"
                            defaultValue={'low'}
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'low',
                                    label: 'Low'
                                },
                                {
                                    value: 'medium',
                                    label: 'Medium'
                                },
                                {
                                    value: 'high',
                                    label: 'High'
                                },
                            ]}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="mb-1">Entity Last Reviewed Date</label>
                        <DatePicker
                            className="custom-datepicker w-100"
                            placeholder="Enter Date (DD/MM/YYYY)"
                            format={'DD/MM/YYYY'}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="mb-1">Entity Next Review Date</label>
                        <input type="text" className='w-100' placeholder='Enter incorporation number' />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="mb-1">Overall Entity Risk Level</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select risk level"
                            onChange={handleChange}
                            options={[
                                {
                                    value: 'low',
                                    label: 'Low'
                                },
                                {
                                    value: 'medium',
                                    label: 'Medium'
                                },
                                {
                                    value: 'high',
                                    label: 'High'
                                },
                            ]}
                        />
                    </div>
                </div>
                <div>
                    <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-4 mt-5 mb-4">
                        <h3 className='primary-title'>Director</h3>
                        <button className='primary-btn'>Analyze</button>
                        <button className='outline-btn'>Web Search</button>
                    </div>
                    <div className="row custom-form mb-4 mb-md-5">
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Salutation:</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select title"
                                defaultValue={'mr'}
                                // onChange={}
                                options={[
                                    {
                                        value: 'mr',
                                        label: 'Mr'
                                    },
                                    {
                                        value: 'mrs',
                                        label: 'Mrs'
                                    },
                                    {
                                        value: 'miss',
                                        label: 'Miss'
                                    },
                                    {
                                        value: 'ms',
                                        label: 'Ms'
                                    },
                                    {
                                        value: 'dr',
                                        label: 'Dr'
                                    },
                                    {
                                        value: 'rev',
                                        label: 'Rev'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">First Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jacob'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Middle Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Smith'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Last Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jackson'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Date of Birth:</label>
                            <DatePicker
                                className="custom-datepicker w-100"
                                placeholder="Enter Date (DD/MM/YYYY)"
                                defaultValue={dayjs('23-08-1986', 'DD/MM/YYYY')}
                                format={'DD/MM/YYYY'}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Country of Birth: </label>
                            <Jurisdiction className="w-100" placeholder="Select country of birth" defaultValue={'IND'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Nationality:</label>
                            <Jurisdiction className="w-100" placeholder="Select nationality" defaultValue={'LKA'} />
                        </div>
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Director Code:</label>
                            <input type="text" className="w-100" placeholder="Enter code" defaultValue={'D002'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Risk Level</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select risk level"
                                defaultValue={'low'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'low',
                                        label: 'Low'
                                    },
                                    {
                                        value: 'medium',
                                        label: 'Medium'
                                    },
                                    {
                                        value: 'high',
                                        label: 'High'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">PEP</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select"
                                defaultValue={'Y'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'Y',
                                        label: 'Yes'
                                    },
                                    {
                                        value: 'N',
                                        label: 'No'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Tax Info Number</label>
                            <input type="text" className="w-100" placeholder="Enter tax info number" defaultValue={'04563218'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Passport Number:</label>
                            <input type="text" className="w-100" placeholder="Enter passport number" defaultValue={'021545879654'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Expiry Date</label>
                            <DatePicker
                                className="custom-datepicker w-100"
                                placeholder="Enter Date (DD/MM/YYYY)"
                                defaultValue={dayjs('12-12-2028', 'DD/MM/YYYY')}
                                format={'DD/MM/YYYY'}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Date of Appointment: </label>
                            <DatePicker
                                className="custom-datepicker w-100"
                                placeholder="Enter Date (DD/MM/YYYY)"
                                defaultValue={dayjs('04-12-2022', 'DD/MM/YYYY')}
                                format={'DD/MM/YYYY'}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Date of Resignation: </label>
                            <DatePicker
                                className="custom-datepicker w-100"
                                placeholder="Enter Date (DD/MM/YYYY)"
                                defaultValue={dayjs('16-08-2023', 'DD/MM/YYYY')}
                                format={'DD/MM/YYYY'}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3 mt-5 mb-4">
                        <h3 className='primary-title'>Shareholder</h3>
                        <button className='primary-btn'>Analyze</button>
                        <button className='outline-btn'>Web Search</button>
                    </div>
                    <div className="row custom-form">
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Salutation:</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select title"
                                defaultValue={'mr'}
                                // onChange={}
                                options={[
                                    {
                                        value: 'mr',
                                        label: 'Mr'
                                    },
                                    {
                                        value: 'mrs',
                                        label: 'Mrs'
                                    },
                                    {
                                        value: 'miss',
                                        label: 'Miss'
                                    },
                                    {
                                        value: 'ms',
                                        label: 'Ms'
                                    },
                                    {
                                        value: 'dr',
                                        label: 'Dr'
                                    },
                                    {
                                        value: 'rev',
                                        label: 'Rev'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">First Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jacob'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Middle Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Smith'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Last Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jackson'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Date of Birth:</label>
                            <DatePicker
                                className="custom-datepicker w-100"
                                placeholder="Enter Date (DD/MM/YYYY)"
                                defaultValue={dayjs('23-08-1986', 'DD/MM/YYYY')}
                                format={'DD/MM/YYYY'}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Country of Birth: </label>
                            <Jurisdiction className="w-100" placeholder="Select country of birth" defaultValue={'IND'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Nationality:</label>
                            <Jurisdiction className="w-100" placeholder="Select nationality" defaultValue={'LKA'} />
                        </div>
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Passport Number:</label>
                            <input type="text" className="w-100" placeholder="Enter passport number" defaultValue={'021545879654'} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="">Expiry Date</label>
                            <DatePicker
                                className="custom-datepicker w-100"
                                placeholder="Enter Date (DD/MM/YYYY)"
                                defaultValue={dayjs('12-12-2028', 'DD/MM/YYYY')}
                                format={'DD/MM/YYYY'}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">No. of Shares Held:</label>
                            <input type="text" className="w-100" placeholder="Enter no. of shares held" defaultValue={'1000'} />
                        </div>
                        <div className="col-md-3 mb-4">

                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Shares Code:</label>
                            <input type="text" className="w-100" placeholder="Enter code" defaultValue={'D002'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Risk Level</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select risk level"
                                defaultValue={'low'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'low',
                                        label: 'Low'
                                    },
                                    {
                                        value: 'medium',
                                        label: 'Medium'
                                    },
                                    {
                                        value: 'high',
                                        label: 'High'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">PEP</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select"
                                defaultValue={'Y'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'Y',
                                        label: 'Yes'
                                    },
                                    {
                                        value: 'N',
                                        label: 'No'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Tax Info Number</label>
                            <input type="text" className="w-100" placeholder="Enter tax info number" defaultValue={'04563218'} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3 mt-5 mb-4">
                        <h3 className='primary-title'>UBO</h3>
                        <button className='primary-btn'>Analyze</button>
                        <button className='outline-btn'>Web Search</button>
                    </div>
                    <div className="row custom-form">
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Salutation:</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select title"
                                defaultValue={'mr'}
                                // onChange={}
                                options={[
                                    {
                                        value: 'mr',
                                        label: 'Mr'
                                    },
                                    {
                                        value: 'mrs',
                                        label: 'Mrs'
                                    },
                                    {
                                        value: 'miss',
                                        label: 'Miss'
                                    },
                                    {
                                        value: 'ms',
                                        label: 'Ms'
                                    },
                                    {
                                        value: 'dr',
                                        label: 'Dr'
                                    },
                                    {
                                        value: 'rev',
                                        label: 'Rev'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">First Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jacob'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Middle Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Smith'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Last Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jackson'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Date of Birth:</label>
                            <DatePicker
                                className="custom-datepicker w-100"
                                placeholder="Enter Date (DD/MM/YYYY)"
                                defaultValue={dayjs('23-08-1986', 'DD/MM/YYYY')}
                                format={'DD/MM/YYYY'}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Country of Birth: </label>
                            <Jurisdiction className="w-100" placeholder="Select country of birth" defaultValue={'IND'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Nationality:</label>
                            <Jurisdiction className="w-100" placeholder="Select nationality" defaultValue={'LKA'} />
                        </div>
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Passport Number:</label>
                            <input type="text" className="w-100" placeholder="Enter passport number" defaultValue={'021545879654'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Expiry Date</label>
                            <DatePicker
                                className="custom-datepicker w-100"
                                placeholder="Enter Date (DD/MM/YYYY)"
                                defaultValue={dayjs('12-12-2028', 'DD/MM/YYYY')}
                                format={'DD/MM/YYYY'}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Ownership Percentage:</label>
                            <input type="text" className="w-100" placeholder="Enter ownership percentage" defaultValue={'49%'} />
                        </div>
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Total Shares Held:</label>
                            <input type="text" className="w-100" placeholder="Enter total shares held" defaultValue={'1000'} />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Risk Level</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select risk level"
                                defaultValue={'low'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'low',
                                        label: 'Low'
                                    },
                                    {
                                        value: 'medium',
                                        label: 'Medium'
                                    },
                                    {
                                        value: 'high',
                                        label: 'High'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">PEP</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select"
                                defaultValue={'Y'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'Y',
                                        label: 'Yes'
                                    },
                                    {
                                        value: 'N',
                                        label: 'No'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="">Tax Info Number</label>
                            <input type="text" className="w-100" placeholder="Enter tax info number" defaultValue={'04563218'} />
                        </div>
                    </div>
                </div>
                <h4 className='primary-title mt-md-5 mt-4 mb-4'>Statutory Details</h4>
                <h6 className='sub-title mb-4'>Location of Registers</h6>
                <div className="overview-listing">
                    <div className="mb-4">
                        <label htmlFor="">Address Line 01</label>
                        <p>No. 32,</p>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="">Address Line 02</label>
                        <p>Sample Street,</p>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="">Address Line 03</label>
                        <p>Colombo 02, Sri Lanka.</p>
                    </div>
                </div>
                <div className="my-4 my-md-5">
                    <label htmlFor="" className="me-3 mb-1">Seal Availability</label>
                    <Select
                        className="custom-select mw-300"
                        placeholder="Select industry"
                        onChange={handleChange}
                        defaultValue={'Y'}
                        options={[
                            {
                                value: 'Y',
                                label: 'Yes'
                            },
                            {
                                value: 'N',
                                label: 'No'
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}
