import React, { useState } from 'react'
import { Select } from 'antd';

export default function PostIncorp() {
    const [serviceFee, setServiceFee] = useState(0)
    const postIncropType = [
        {
            value: 'COI',
            label: 'Certificate of Incumbency (COI)'
        },
        {
            value: 'COGS',
            label: 'Certificate of Good Standing (COGS)'
        },
        {
            value: 'ROD',
            label: 'Register of Directors'
        },
        {
            value: 'SR',
            label: 'Share Registry'
        },
        {
            value: 'COD',
            label: 'Change Of Director (COD)'
        },
        {
            value: 'COSH',
            label: 'Change of Shareholder (COSH)'
        },
        {
            value: 'COA',
            label: 'Change of Client / Administration (COA)'
        },
        {
            value: 'COC',
            label: 'Change of Contact Point (COC)'
        },
        {
            value: 'TO',
            label: 'Transfer Out (TO)'
        },
        {
            value: 'CO',
            label: 'Continuation Out (CO)'
        },
    ]

    const docUploadSchema = {
        'COI': [],
        'COGS': [],
        'ROD': [
            {
                label: 'Latest Client information Sheet',
                slug: 'latest-client-information-sheet'
            }
        ],
        'SR': [
            {
                label: 'Latest Client information Sheet',
                slug: 'latest-client-information-sheet'
            }
        ],
        'COD': [
            {
                label: 'Resolution of Appointment',
                slug: 'resolution-of-appointment'
            },
            {
                label: 'Resolution of Resignation',
                slug: 'resolution-of-resignation'
            },
            {
                label: 'Consent to Act as a Director',
                slug: 'consent-to-act-as-a-director'
            },
            {
                label: 'Client Information Sheet',
                slug: 'client-information-sheet'
            },
            {
                label: 'Passport / ID Proof',
                slug: 'passport-id-proof'
            },
            {
                label: 'Address Proof',
                slug: 'address-proof'
            },
            {
                label: 'Letter of Resignation',
                slug: 'letter-of-resignation'
            },
        ],
        'COSH': [
            {
                label: 'Updated Client Information Sheet',
                slug: 'updated-client-information-sheet'
            },
            {
                label: 'Instrument of Share Transfer',
                slug: 'instrument-of-share-transfer'
            },
            {
                label: 'Share Transfer Resolution ',
                slug: 'share-transfer-resolution'
            },
            {
                label: 'Passport / ID Proof',
                slug: 'passport-id-proof'
            },
            {
                label: 'Address Proof',
                slug: 'address-proof'
            },
            {
                label: 'Letter of Resignation',
                slug: 'letter-of-resignation'
            },
        ],
        'COA': [
            {
                label: 'Resolution of Change in Administration',
                slug: 'resolution-of-change-in-administration'
            },
            {
                label: 'Client Information Sheet',
                slug: 'client-information-sheet'
            },
            {
                label: 'KYC 01',
                slug: 'kyc-01'
            },
            {
                label: 'KYC 02',
                slug: 'kyc-02'
            },
            {
                label: 'KYC 03',
                slug: 'kyc-03'
            },
        ],
        'COC': [
            {
                label: 'Passport / ID Proof',
                slug: 'passport-id-proof'
            },
            {
                label: 'Address Proof',
                slug: 'address-proof'
            },
            {
                label: 'Letter of Appointment as Contact Point',
                slug: 'letter-of-appointment-as-contact-point'
            },
        ],
        'TO': [
            {
                label: 'Letter of Request - Transfer Out',
                slug: 'letter-of-request-transfer-out'
            },
            {
                label: 'New RA Acceptance Letter',
                slug: 'new-ra-acceptance-letter'
            },
        ],
        'CO': [],
    }

    const [selectedType, setSelectedType] = useState(null)

    const getSelectType = (value, obj) => {
        // console.log(obj);
        setSelectedType(obj)
        switch (value) {
            case 'COI':
                setServiceFee(60)
                break;
            case 'COGS':
                setServiceFee(100)
                break;
            case 'ROD':
                setServiceFee(50)
                break;
            case 'SR':
                setServiceFee(120)
                break;
            case 'COD':
                setServiceFee(150)
                break;
            case 'COSH':
                setServiceFee(100)
                break;
            case 'COA':
                setServiceFee(80)
                break;
            case 'COC':
                setServiceFee(200)
                break;
            case 'TO':
                setServiceFee(170)
                break;
            case 'CO':
                setServiceFee(50)
                break;
            default:
                break;
        }
        // console.log(serviceFee);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <div>
            <h1 className='page-title mb-4'>Post Incorp</h1>
            <h2 className='primary-title mb-4'>Post Incorp List</h2>
            <div className="row custom-form mb-4 align-items-end gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3">Select Service</label>
                    <Select
                        className="custom-select w-100"
                        placeholder="Select"
                        onChange={getSelectType}
                        options={postIncropType}
                    />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3">Service Fee</label>
                    <input type="text" className='w-100' value={`$${serviceFee}`} disabled />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3">Bank Reference</label>
                    <input type="text" className='w-100' placeholder='Enter bank reference' />
                </div>
            </div>
            <div className="row custom-form mb-4 align-items-end gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="">Client Name</label>
                    <input type="text" className='w-100' placeholder='Enter client name' />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3">Client Type</label>
                    <Select
                        className="custom-select w-100"
                        placeholder="Select"
                        onChange={handleChange}
                        options={[]}
                    />
                </div>
            </div>
            <div className="row custom-form mb-4 align-items-end gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="">Entity Name</label>
                    <input type="text" className='w-100' placeholder='Enter entity name' />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="">Entity Code</label>
                    <input type="text" className='w-100' placeholder='Enter entity code' />
                </div>
            </div>
            <div>
                    <button className='primary-btn'>Submit Request</button>
            </div>
            {selectedType &&
                <div className='container-fluid px-0 mt-5'>
                    <h2 className='primary-title mb-4'>{selectedType?.label}</h2>
                    <div className="custom-form">
                        <div className="row">
                            {docUploadSchema[selectedType?.value]?.length > 0 && docUploadSchema[selectedType?.value]?.map((doc, i) => {
                                return (
                                    <div className='col-md-3 mb-4' key={i}>
                                        <label htmlFor={doc.slug} className="me-3">{doc.label}</label>
                                        <div className="uploader">
                                            <div className="upload-wrapper">
                                                <input type="file" name={doc.slug} id={doc.slug} />
                                                <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                                <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                                <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
