import React from 'react'
import { Select } from 'antd';

export default function Others() {
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    let arr = [1, 2, 3]
    return (
        <div className='custom-form'>
            <div className="mb-4">
                <label htmlFor="" className="me-3 mb-1">Role</label>
                <Select
                    className="custom-select mw-300"
                    placeholder="Select role"
                    onChange={handleChange}
                    options={[
                        {
                            value: 'director',
                            label: 'Director'
                        },
                        {
                            value: 'shareholder',
                            label: 'Shareholder'
                        },
                        {
                            value: 'ubo',
                            label: 'UBO'
                        },
                    ]}
                />
            </div>
            <h6 className='primary-title mb-4'>Others</h6>
            <div className="row">
                {arr.map((item, i) => {
                    return (
                        <div className="col-md-3 mb-4" key={i}>
                            <label htmlFor="passport-id" className="me-3">Pack 0{item}</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="passport-id" id="passport-id" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
