import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Select, DatePicker } from 'antd'

export default function EntityOverview() {
    const [historyShow, setHistoryShow] = useState([])
    const [uboHistory, setUboHistory] = useState(false)

    const toggleHistory = (index) => {
        let arr = [...historyShow]
        let item = arr.findIndex(hs => hs === index)
        if (item !== -1) {
            arr.splice(item, 1);
        } else {
            arr.push(index)
        }
        setHistoryShow(arr)
    }

    let directors = [1, 2, 3]
    let shareholders = [1, 2, 3]
    let ubo = [1]

    const columns = [
        {
            title: 'Share Certificate Number',
            dataIndex: 'shareNumber',
            sorter: (a, b) => a.shareNumber - b.shareNumber,
        },
        {
            title: 'Number of Shares',
            dataIndex: 'noShares',
            sorter: (a, b) => a.noShares - b.noShares,
        },
        {
            title: <div className='d-flex align-items-center'>Issued Date&nbsp;<i className="fal fa-question-circle"></i></div>,
            dataIndex: 'issuedDate',
            sorter: (a, b) => a.issuedDate - b.issuedDate,
        },
        {
            title: <div className='d-flex align-items-center'>Transfer Date&nbsp;<i className="fal fa-question-circle"></i></div>,
            dataIndex: 'transferDate',
            sorter: (a, b) => a.transferDate - b.transferDate,
        },
        {
            title: 'Transfer Amount',
            dataIndex: 'transferAmount',
            sorter: (a, b) => a.transferAmount - b.transferAmount,
        },
        {
            title: 'Par value',
            dataIndex: 'parValue',
            sorter: (a, b) => a.parValue - b.parValue,
        },
        {
            title: '',
            render: (_, { id }) => (
                <div className="d-flex gap-2 align-items-center justify-content-end">
                    <button className='action-btn delete-btn'><i className="far fa-trash-alt"></i></button>
                    <button className='action-btn'><i className="far fa-pencil"></i></button>
                </div>
            ),
        },
    ];
    const data = [
        {
            key: '1',
            id: '1',
            shareNumber: 55,
            noShares: 100,
            issuedDate: '11-12-2023',
            transferDate: '12-12-2023',
            transferAmount: 500,
            parValue: 100
        },
        {
            key: '2',
            id: '2',
            shareNumber: 56,
            noShares: 500,
            issuedDate: '13-12-2023',
            transferDate: '14-12-2023',
            transferAmount: 800,
            parValue: 100
        },
        {
            key: '3',
            id: '3',
            shareNumber: 57,
            noShares: 250,
            issuedDate: '15-12-2023',
            transferDate: '17-12-2023',
            transferAmount: 400,
            parValue: 100
        },
        {
            key: '4',
            id: '4',
            shareNumber: 58,
            noShares: 120,
            issuedDate: '18-12-2023',
            transferDate: '19-12-2023',
            transferAmount: 1500,
            parValue: 100
        },
    ];

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4 mb-md-5">
                <h1 className='page-title mb-3 mb-md-0'>Entity Overview</h1>
                <Link to={'/app/incorporation-pack'} className='primary-btn'>Export</Link>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <h4 className='primary-title mb-4'>Entity</h4>
                    <div className="row overview-listing mb-5">
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Name:</label>
                            <p>Sample Entity</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Type:</label>
                            <p>Trust</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Code:</label>
                            <p>E001</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Status:</label>
                            <p>Active</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Client Name:</label>
                            <p>John Doe</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Client Code:</label>
                            <p>C040</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Client Status:</label>
                            <p>Active</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Parent Client Name:</label>
                            <p>John Doe</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Parent Client Code:</label>
                            <p>C040</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Parent Client Status:</label>
                            <p>Active</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Incorporation Number:</label>
                            <p>04638274</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Jurisdiction:</label>
                            <p>Colombo</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Incorporated Date:</label>
                            <p>22-12-2023</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Grouping:</label>
                            <p>Random</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Related Entities:</label>
                            <p>E002, E003</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Composite Risk Level:</label>
                            <p>High</p>
                        </div>
                    </div>
                    <h4 className='primary-title mb-4'>Primary Contact</h4>
                    <div className="row overview-listing mb-5">
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Primary Contact Name:</label>
                            <p>John Doe</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Primary Contact Email:</label>
                            <p>john@example.com</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Primary Contact Mobile:</label>
                            <p>+94 77 123 4567</p>
                        </div>
                    </div>
                    <h4 className='primary-title mb-4'>Location of Registers</h4>
                    <div className="overview-listing mb-5">
                        <div className="mb-4">
                            <label htmlFor="">Address Line 01</label>
                            <p>No. 32,</p>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="">Address Line 02</label>
                            <p>Sample Street,</p>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="">Address Line 03</label>
                            <p>Colombo 02, Sri Lanka.</p>
                        </div>
                    </div>
                    <h4 className='primary-title mb-4'>Location of Financial Records</h4>
                    <div className="overview-listing">
                        <div className="mb-4">
                            <label htmlFor="">Address Line 01</label>
                            <p>No. 32,</p>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="">Address Line 02</label>
                            <p>Sample Street,</p>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="">Address Line 03</label>
                            <p>Colombo 02, Sri Lanka.</p>
                        </div>
                    </div>
                </div>
                <div className="row overview-listing mb-5">
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Title</label>
                        <p>Mr.</p>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Name of Financial Record Holder</label>
                        <p>John Doe</p>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Email:</label>
                        <p>john@example.com</p>
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Primary Contact Mobile:</label>
                        <p>+94 77 123 4567</p>
                    </div>
                </div>
                <h4 className='primary-title mb-4'>Directors</h4>
                {directors.map((a, i) => {
                    return (
                        <div key={i}>
                            <h3 className='sub-title mb-4'>Director 0{a} &nbsp;<i className="fal fa-question-circle"></i></h3>
                            <div className="row overview-listing mb-4 mb-md-5">
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Individual Masterfile Code:</label>
                                    <p>D002</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Salutation:</label>
                                    <p>Mr.</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">First Name:</label>
                                    <p>Jacob</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Middle Name:</label>
                                    <p>Smith</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Last Name:</label>
                                    <p>Jackson</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Birth:</label>
                                    <p>23-08-1986</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Country of Birth: </label>
                                    <p>Sri Lanka</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Nationality:</label>
                                    <p>Sri Lankan</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Risk Level</label>
                                    <p>Low</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">PEP</label>
                                    <p>Yes</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Tax Info Number </label>
                                    <p>04563218</p>
                                </div>
                                <div className="col-md-3 mb-4">

                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Passport Number:</label>
                                    <p>021545879654</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Expiry Date</label>
                                    <p>12-12-2028</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Appointment: </label>
                                    <p>04-12-2022</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Resignation: </label>
                                    <p>16-08-2023</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <h4 className='primary-title mb-4'>Shareholders</h4>
                {shareholders.map((a, i) => {
                    return (
                        <div key={i} className='mb-4'>
                            <h3 className='sub-title mb-4'>Shareholder 0{a} &nbsp;<i className="fal fa-question-circle"></i></h3>
                            <div className="row overview-listing">
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Individual Masterfile Code:</label>
                                    <p>S002</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Salutation:</label>
                                    <p>Mr.</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">First Name:</label>
                                    <p>Jacob</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Middle Name:</label>
                                    <p>Smith</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Last Name:</label>
                                    <p>Jackson</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Birth:</label>
                                    <p>23-08-1986</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Country of Birth: </label>
                                    <p>Sri Lanka</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Nationality:</label>
                                    <p>Sri Lankan</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Passport Number:</label>
                                    <p>021545879654</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Expiry Date</label>
                                    <p>12-12-2028</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">No. of Shares Held</label>
                                    <p>1000</p>
                                </div>
                                <div className="col-md-3 mb-4">

                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Risk Level</label>
                                    <p>Low</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">PEP</label>
                                    <p>Yes</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Tax Info Number </label>
                                    <p>04563218</p>
                                </div>
                                <div className="col-md-3 mb-4">

                                </div>
                            </div>
                            <h6 className='hyper-link' onClick={() => toggleHistory(a)}>Detailed History <i className={`fas fa-chevron-down ${historyShow.findIndex(hs => hs === a) !== -1 ? 'fa-rotate-180' : ''}`}></i></h6>
                            {historyShow.findIndex(hs => hs === a) !== -1 ?
                                <Table className='custom-table-light mt-md-1' columns={columns} dataSource={data} pagination={false} />
                                :
                                <></>
                            }
                        </div>
                    )
                })}
                <h4 className='primary-title mb-4'>UBO</h4>
                {ubo.map((a, i) => {
                    return (
                        <div key={i} className='mb-4'>
                            <h3 className='sub-title mb-4'>UBO 0{a} &nbsp;<i className="fal fa-question-circle"></i></h3>
                            <div className="row overview-listing">
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Individual Masterfile Code:</label>
                                    <p>U002</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Salutation:</label>
                                    <p>Mr.</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">First Name:</label>
                                    <p>Jacob</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Middle Name:</label>
                                    <p>Smith</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Last Name:</label>
                                    <p>Jackson</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Birth:</label>
                                    <p>23-08-1986</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Country of Birth: </label>
                                    <p>Sri Lanka</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Nationality:</label>
                                    <p>Sri Lankan</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Passport Number:</label>
                                    <p>021545879654</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Expiry Date</label>
                                    <p>12-12-2028</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Ownership Pecentage:</label>
                                    <p>49%</p>
                                </div>
                                <div className="col-md-3">

                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Total Shares Held:</label>
                                    <p>1000</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Risk Level</label>
                                    <p>Low</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">PEP</label>
                                    <p>Yes</p>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Tax Info Number</label>
                                    <p>04563218</p>
                                </div>
                            </div>
                            <h6 className='hyper-link' onClick={() => setUboHistory(!uboHistory)}>Detailed History <i className={`fas fa-chevron-down ${uboHistory ? 'fa-rotate-180' : ''}`}></i></h6>
                            {uboHistory ?
                                <Table className='custom-table-light mt-md-1' columns={columns} dataSource={data} pagination={false} />
                                :
                                <></>
                            }
                        </div>
                    )
                })}
                <h4 className='primary-title mb-4'>CDD Information</h4>
                <div className="overview-listing">
                    <div className='mb-4'>
                        <label htmlFor="" className="me-3">Source of Wealth</label>
                        <p>Lorem ipsum dolor sit amet.</p>
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="" className="me-3">Source of Funds</label>
                        <p>Lorem ipsum dolor sit amet.</p>
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="" className="me-3">Purpose of Business</label>
                        <p>Lorem ipsum dolor sit amet.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="me-3 mb-1">Geography of Business</label>
                                <p>Sample</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="" className="me-3 mb-1">Business Activity</label>
                            <p>Sample</p>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="me-3 mb-1">Business Risk</label>
                                <p>Low</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="mb-1">Entity Last Reviewed Date</label>
                                <p>27-12-2023</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="mb-1">Entity Next Review Date</label>
                                <p>27-12-2023</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="mb-1">Overall Entity Risk Level</label>
                                <p>Low</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <label htmlFor="" className="me-3 mb-1">Reminder</label>
                            <p>2</p>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="me-3 mb-1">Industry</label>
                                <p>Sample</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4 mb-md-5">
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="mb-1">Reminder 1 Date</label>
                                <p>29-12-2023</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="mb-1">Reminder 2 Date</label>
                                <p>30-12-2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}