import React from 'react'
import { Link } from 'react-router-dom'
import { Select, DatePicker } from 'antd'

import Jurisdiction from '../../components/Jurisdiction'

export default function Shareholder() {
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-4 mb-md-5">
                <h1 className='page-title'>Shareholder</h1>
                <Link to={'/app/incorporation-pack'} className='primary-btn'>Export</Link>
            </div>
            <div className="custom-form">
                <div className="d-flex gap-3 flex-column flex-md-row align-items-start align-items-md-center mb-4">
                    <h4 className='primary-title'>Shareholder</h4>
                    <button className='primary-btn'>Analyze</button>
                    <button className='outline-btn'>Web Search</button>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Title</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select title"
                            // onChange={}
                            options={[
                                {
                                    value: 'mr',
                                    label: 'Mr'
                                },
                                {
                                    value: 'mrs',
                                    label: 'Mrs'
                                },
                                {
                                    value: 'miss',
                                    label: 'Miss'
                                },
                                {
                                    value: 'ms',
                                    label: 'Ms'
                                },
                                {
                                    value: 'dr',
                                    label: 'Dr'
                                },
                                {
                                    value: 'rev',
                                    label: 'Rev'
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">First Name</label>
                        <input type="text" className='w-100' placeholder="Enter first name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Middle Name</label>
                        <input type="text" className='w-100' placeholder="Enter middle name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Last Name</label>
                        <input type="text" className='w-100' placeholder="Enter last name" />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Gender</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select gender"
                            // onChange={}
                            options={[
                                {
                                    value: 'male',
                                    label: 'Male'
                                },
                                {
                                    value: 'female',
                                    label: 'Female'
                                },
                            ]}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Nationality</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select nationality"
                            // onChange={}
                            options={[]}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Shareholder Status</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select status"
                            defaultValue={'Y'}
                            // onChange={}
                            options={[
                                {
                                    value: 'Y',
                                    label: 'Active'
                                },
                                {
                                    value: 'N',
                                    label: 'Inactive'
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Date of Birth</label>
                        <DatePicker
                            className="custom-datepicker w-100"
                            placeholder="Enter Date (DD/MM/YYYY)"
                            format={'DD/MM/YYYY'}
                        // onChange={}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Country of Birth</label>
                        <Jurisdiction
                            className="w-100"
                            placeholder="Select country of birth"
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="me-3 mb-1">Risk Level</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select risk level"
                            defaultValue={'low'}
                            // onChange={handleChange}
                            options={[
                                {
                                    value: 'low',
                                    label: 'Low'
                                },
                                {
                                    value: 'medium',
                                    label: 'Medium'
                                },
                                {
                                    value: 'high',
                                    label: 'High'
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Passport Number</label>
                        <input type="text" className='w-100' placeholder="Enter passport number" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Expiry Date</label>
                        <DatePicker
                            className="custom-datepicker w-100"
                            placeholder="Enter Date (DD/MM/YYYY)"
                            format={'DD/MM/YYYY'}
                        // onChange={}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">PEP</label>
                        <input type="text" className='w-100' placeholder="Enter PEP" />
                    </div>
                </div>
                <div className="row mb-5 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Number of Shares</label>
                        <input type="text" className='w-100' placeholder="Enter number of shares" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Share Issued Date</label>
                        <DatePicker
                            className="custom-datepicker w-100"
                            placeholder="Enter Date (DD/MM/YYYY)"
                            format={'DD/MM/YYYY'}
                        // onChange={}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Share Transfer Date</label>
                        <DatePicker
                            className="custom-datepicker w-100"
                            placeholder="Enter Date (DD/MM/YYYY)"
                            format={'DD/MM/YYYY'}
                        // onChange={}
                        />
                    </div>
                </div>

                <div className="d-flex gap-3 flex-column flex-md-row align-items-start align-items-md-center mb-4">
                    <h4 className='primary-title'>UBO</h4>
                    <button className='primary-btn'>Analyze</button>
                    <button className='outline-btn'>Web Search</button>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Title</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select title"
                            // onChange={}
                            options={[
                                {
                                    value: 'mr',
                                    label: 'Mr'
                                },
                                {
                                    value: 'mrs',
                                    label: 'Mrs'
                                },
                                {
                                    value: 'miss',
                                    label: 'Miss'
                                },
                                {
                                    value: 'ms',
                                    label: 'Ms'
                                },
                                {
                                    value: 'dr',
                                    label: 'Dr'
                                },
                                {
                                    value: 'rev',
                                    label: 'Rev'
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">First Name</label>
                        <input type="text" className='w-100' placeholder="Enter first name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Middle Name</label>
                        <input type="text" className='w-100' placeholder="Enter middle name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Last Name</label>
                        <input type="text" className='w-100' placeholder="Enter last name" />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Gender</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select gender"
                            // onChange={}
                            options={[
                                {
                                    value: 'male',
                                    label: 'Male'
                                },
                                {
                                    value: 'female',
                                    label: 'Female'
                                },
                            ]}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Nationality</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select nationality"
                            // onChange={}
                            options={[]}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">UBO Status</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select status"
                            defaultValue={'Y'}
                            // onChange={}
                            options={[
                                {
                                    value: 'Y',
                                    label: 'Active'
                                },
                                {
                                    value: 'N',
                                    label: 'Inactive'
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Date of Birth</label>
                        <DatePicker
                            className="custom-datepicker w-100"
                            placeholder="Enter Date (DD/MM/YYYY)"
                            format={'DD/MM/YYYY'}
                        // onChange={}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Country of Birth</label>
                        <Jurisdiction
                            className="w-100"
                            placeholder="Select country of birth"
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="me-3 mb-1">Risk Level</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select risk level"
                            defaultValue={'low'}
                            // onChange={handleChange}
                            options={[
                                {
                                    value: 'low',
                                    label: 'Low'
                                },
                                {
                                    value: 'medium',
                                    label: 'Medium'
                                },
                                {
                                    value: 'high',
                                    label: 'High'
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Passport Number</label>
                        <input type="text" className='w-100' placeholder="Enter passport number" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">Expiry Date</label>
                        <DatePicker
                            className="custom-datepicker w-100"
                            placeholder="Enter Date (DD/MM/YYYY)"
                            format={'DD/MM/YYYY'}
                        // onChange={}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="me-3 mb-1">PEP</label>
                        <input type="text" className='w-100' placeholder="Enter PEP" />
                    </div>
                </div>
            </div>
        </div>
    )
}
