import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { Tooltip, Select, Switch, DatePicker } from 'antd';
import dayjs from 'dayjs';

const FormZ = forwardRef((props, ref) => {
  let { formSchema, formData, theme, customClass, formOptions, updateOptions, onKeyPress } = props;

  const [formValues, setFormValues] = useState({});
  const [options, setOptions] = useState([]);
  const [togglePass, setTogglePass] = useState(false)

  useEffect(() => {
    getFormFields()
    setTogglePass(false)
  }, [formData])

  useEffect(() => {
    // console.log(formOptions, 'formOptions');
    if (formOptions) {
      setOptions(formOptions)
    }
  }, [formOptions])

  const getFormFields = () => {
    let formFields = {}
    formSchema.map(tag => formFields[tag.name] = { value: formData ? formData[tag.name] : tag.value, error: '', required: tag.required ? true : false })
    setFormValues(formFields)
  }

  var emailValidate = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  var passValidate = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  var passErrMeg = 'Password must be 8 to 15 characters at least one uppercase letter, one numeric digit, and one special character'

  const getInputValue = (field, value) => {
    let values = { ...formValues }
    values[field].value = value
    if (values[field].required) {
      if (value && value !== ' ') {
        values[field].error = ''
        if (field == 'email') if (!emailValidate.test(value)) values[field].error = 'Enter valid email address'
        if (field == 'password' && formSchema['password']?.validation) if (!value.match(passValidate)) values[field].error = <>Invalid password <Tooltip placement="top" title={passErrMeg}><i className="far fa-question-circle"></i></Tooltip></>
      } else {
        values[field].error = `${field.replace('_', ' ').replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })} was required`
      }
    }
    // console.log(values);
    if (updateOptions) {
      updateOptions(value)
    }
    setFormValues(values)
  }

  function checkEmpty() {
    let values = { ...formValues }
    let emptyArr = []
    for (var key in values) {
      if (values[key].required) {
        if (values[key].value === "" || values[key].value === null || values[key].error !== '') {
          emptyArr.push(key)
        }
      }
    }
    return emptyArr;
  }

  useImperativeHandle(ref, () => ({
    validFrom() {
      let values = { ...formValues }
      let empty = checkEmpty()
      if (empty?.length > 0) {
        empty.forEach((field) => {
          if (values[field].value) {
            if (field == 'email') if (!emailValidate.test(values['email'].value)) values['email'].error = 'Enter valid email address'
          } else {
            values[field].error = `${field.replace('_', ' ').replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })} was required`
          }
        })
      } else {
        return true
      }
      setFormValues(values)
      return false
    },

    getPayload() {
      let values = { ...formValues }
      let payload = {}
      for (var key in values) {
        if (values[key]?.value) {
          payload[key] = values[key]?.value
        }
      }
      return payload
    },

    clearForm() {
      getFormFields()
      setTogglePass(false)
    }
  }));

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <form className={customClass ?? ''}>
      {formSchema && formSchema?.map((input, index) => {
        switch (input?.type) {
          case 'select':
            return (
              <div key={index} className={`input-box ${input?.required ? 'req' : ''} ${formValues[input.name]?.error ? 'input-error' : ''}`}>
                <label htmlFor={input?.name}>{input?.label} <span className='err-meg'>{formValues[input.name]?.error}</span></label>
                <Select
                  showSearch
                  filterOption={filterOption}
                  value={formValues[input.name]?.value || []}
                  className='custom-select w-100'
                  placeholder={"Select category"}
                  onChange={(value) => getInputValue(input?.name, value)}
                  options={input?.options || options[input.name]}
                />
              </div>
            )
          case 'textarea':
            return (
              <div key={index} className={`input-box ${input?.required ? 'req' : ''} ${theme == 'primary' ? 'primary-theme' : ''} ${formValues[input.name]?.error ? 'input-error' : ''}`}>
                <label htmlFor={input?.name}>{input?.label} <span className='err-meg'>{formValues[input.name]?.error}</span></label>
                <textarea name={input?.name} style={input?.style} placeholder={input?.placeholder} value={formValues[input.name]?.value || ''} onChange={(e) => getInputValue(input?.name, e.target.value)} disabled={input?.disabled}></textarea>
              </div>
            )
          case 'switch':
            return (
              <div key={index} className={`input-box ${input?.required ? 'req' : ''} ${formValues[input.name]?.error ? 'input-error' : ''}`}>
                <label htmlFor={input?.name}>{input?.label} <span className='err-meg'>{formValues[input.name]?.error}</span></label>
                <div className="switch-wrapper">
                  <Switch className='custom-switch' checked={formValues[input.name]?.value} onChange={(value) => getInputValue(input?.name, value)} />
                  <h6>{formValues[input.name]?.value ? 'Enabled' : 'Disabled'}</h6>
                </div>
              </div>
            )
          case 'date':
            return (
              <div key={index} className={`input-box ${input?.required ? 'req' : ''} ${formValues[input.name]?.error ? 'input-error' : ''}`}>
                <label htmlFor={input?.name}>{input?.label} <span className='err-meg'>{formValues[input.name]?.error}</span></label>
                <DatePicker className='custom-datepicker' dateFormat="DD-MM-YYYY HH:mm:ss" showTime={input?.showTime || false} value={formValues[input.name]?.value ? dayjs(formValues[input.name]?.value) : ''} onChange={(value) => getInputValue(input?.name, value)} />
              </div>
            )
          default:
            return (
              <div key={index} className={`input-box ${input?.required ? 'req' : ''} ${theme == 'primary' ? 'primary-theme' : ''} ${formValues[input.name]?.error ? 'input-error' : ''}`}>
                <label htmlFor={input?.name}>{input?.label} <span className='err-meg'>{formValues[input.name]?.error}</span></label>
                <div className={`input-field ${input?.disabled ? 'input-disabled' : ''}`}>
                  <input type={input?.type == 'password' ? togglePass ? 'text' : 'password' : input?.type} name={input?.name} placeholder={input?.placeholder} value={formValues[input.name]?.value || ''} onChange={(e) => getInputValue(input?.name, e.target.value)} disabled={input?.disabled} onKeyPress={(e) => e.key === 'Enter' && onKeyPress()} />
                  {input?.tooltip &&
                    <Tooltip title={input.tooltip?.message}><button className='input-after'><i className="fal fa-info-circle"></i></button></Tooltip>
                  }
                  {(input?.type == 'password' && !input?.disabled) &&
                    <button type='button' className='input-after' onClick={() => setTogglePass(!togglePass)}>
                      <i className={`fal ${togglePass ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                    </button>
                  }
                </div>
              </div>
            )
        }
      })}
    </form>
  )
})

export default FormZ