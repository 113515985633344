import React, { useState } from 'react'
import { Checkbox, Modal } from 'antd';
import { Link } from 'react-router-dom';

import PDFIcon from '../assets/img/pdf-icon.png'

import SampleDoc from '../assets/pdf/sample.pdf'
import AMLDoc from '../assets/pdf/aml-verification-guidelines.pdf'
import IncorporationDoc from '../assets/pdf/company-incorporation-application-form.pdf'
import DirectorsConsentDoc from '../assets/pdf/directors-consent-letter.pdf'
import TermsDoc from '../assets/pdf/terms-of-business-agreement.pdf'
import TransferDoc from '../assets/pdf/transfer-in-form-for-bvibc.pdf'
import UBODoc from '../assets/pdf/ubo-source-of-funds-declaration.pdf'
import WrittenConfirmationsDoc from '../assets/pdf/written-confirmations-virtual-assets.pdf'

import { downloadFile } from '../helper';

export default function PreIncorpResolution() {
    const [docModal, setDocModal] = useState(false)
    const [viewDoc, setViewDoc] = useState(null)
    const [viewDocName, setViewDocName] = useState('')
    const [selectAll, setSelectAll] = useState(false)

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const viewDocument = (doc, docName) => {
        setViewDoc(doc)
        setViewDocName(docName)
        setDocModal(true)
    }

    const handleOk = () => {
        setDocModal(false);
        setViewDocName('')
    };
    const handleCancel = () => {
        setDocModal(false);
        setViewDocName('')
    };

    const onSelectAll = (e) => {
        setSelectAll(e.target.checked)
    }

    const DownloadDoc = () => {
        downloadFile(SampleDoc, 'sample_doc.pdf')
    }

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
                <h1 className='page-title mb-3 mb-md-0'>Pre-Incorp Supported Documents</h1>
                <Link to={'/app/relationship-manager'} className='primary-btn'>Export</Link>
            </div>
            <p className='head-label mb-4'>Select Documents to Export :</p>
            <div className="d-flex gap-3 align-items-start align-items-md-center flex-column flex-md-row mb-4">
                <Checkbox onChange={onSelectAll} className='custom-checkbox'>Select All</Checkbox>
                <button className='outline-btn'><i className="far fa-arrow-to-bottom"></i> Download Selected</button>
            </div>
            <div className="row mb-4 gap-ms-2">
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>Inaugural Resolution</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>Inaugural Resolution.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(SampleDoc, 'Inaugural Resolution')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(SampleDoc, 'Inaugural Resolution.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>Appointment of First Director</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>Appointment of First Director.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(SampleDoc, 'Appointment of First Director')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(SampleDoc, 'Appointment of First Director.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>Resolution of Share Allotment</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>Resolution of Share Allotment.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(SampleDoc, 'Resolution of Share Allotment')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(SampleDoc, 'Resolution of Share Allotment.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-4 gap-ms-2">
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>Corporate Seal Draft</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>Corporate Seal Draft.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(SampleDoc, 'Corporate Seal Draft')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(SampleDoc, 'Corporate Seal Draft.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>Directors Consent Letter</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>Directors Consent Letter.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(DirectorsConsentDoc, 'Directors Consent Letter')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(DirectorsConsentDoc, 'Directors Consent Letter.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>Customized M&AA</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>Customized M&AA.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(SampleDoc, 'Customized M&AA')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(SampleDoc, 'Customized M&AA.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-4 gap-ms-2">
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>UBO Source of Funds Declaration</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>UBO Source of Funds Declaration.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(UBODoc, 'UBO Source of Funds Declaration')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(UBODoc, 'UBO Source of Funds Declaration.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>Incorporation Application Form</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>Incorporation Application Form.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(IncorporationDoc, 'Incorporation Application Form')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(IncorporationDoc, 'Incorporation Application Form.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>AML Verification Guidelines</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>AML Verification Guidelines.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(AMLDoc, 'AML Verification Guidelines')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(AMLDoc, 'AML Verification Guidelines.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-4 gap-ms-2">
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>Transfer-In Form for BVIBC</Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>Transfer-In Form for BVIBC.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(TransferDoc, 'Transfer-In Form for BVIBC')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(TransferDoc, 'Transfer-In Form for BVIBC.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>
                        Written Confirmations-Virtual Assets
                    </Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>Written Confirmations.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(WrittenConfirmationsDoc, 'Written Confirmations-Virtual Assets')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(WrittenConfirmationsDoc, 'Written Confirmations-Virtual Assets.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>
                        Terms Of Business Agreement
                    </Checkbox>
                    <div className="uploader w-100">
                        <div className="uploaded-file">
                            <img className='file-icon' src={PDFIcon} alt="" />
                            <div className='ms-2'>
                                <h6>terms-of-business-agreement.pdf</h6>
                                <p>200 KB</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className='view-btn' onClick={() => viewDocument(TermsDoc, 'Terms Of Business Agreement')}><i className="far fa-eye"></i> View</button>
                            <button className='download-btn' onClick={() => downloadFile(TermsDoc, 'Terms Of Business Agreement.pdf')}><i className="far fa-arrow-to-bottom"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title={viewDocName} className='custom-modal' open={docModal} width={1000} onOk={handleOk} onCancel={handleCancel}>
                <div className="document-view">
                    <iframe src={viewDoc} frameBorder="0" height="100%" width="100%"></iframe>
                </div>
            </Modal>
        </div>
    )
}
