import React, { useState } from 'react'

import InhouseOcr from './components/inhouse-ocr'
import ManualOcr from './components/manual-ocr'

export default function OcrExtraction() {
    const [activeChild, setActiveChild] = useState(0)

    return (
        <div>
            <h1 className='page-title mb-4 mb-md-5'>OCR Extraction</h1>
            <div className="row px-0">
                <div className="col-md-3">
                    <ul className='custom-dropdown child-first'>
                        <li>
                            <ul className={`child-dropdown show ps-0`}>
                                <li className={activeChild == 0 ? 'active' : ''} onClick={() => setActiveChild(0)}>
                                    <p><i className="fal fa-file-search"></i> Inhouse OCR Extraction</p>
                                </li>
                                <li className={activeChild == 1 ? 'active' : ''} onClick={() => setActiveChild(1)}>
                                    <p><i className="fal fa-file-signature"></i> Manual OCR Extraction</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="col-md-9">
                    {activeChild == 0 &&
                        <InhouseOcr />
                    }
                    {activeChild == 1 &&
                        <ManualOcr />
                    }
                </div>
            </div>
        </div>
    )
}
