import React, { useState } from 'react'
import { Checkbox, Modal } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';

import PDFIcon from '../assets/img/pdf-icon.png'
import DOCIcon from '../assets/img/doc-icon.png'

import SampleDoc from '../assets/pdf/sample.pdf'
import SampleDoc2 from '../assets/pdf/sample2.pdf'

import { downloadFile } from '../helper';

export default function Storage() {
    const [docModal, setDocModal] = useState(false)
    const [viewDocName, setViewDocName] = useState('')
    const [activeDropdown, setActiveDropdown] = useState(0)
    const [activeChild, setActiveChild] = useState(0)
    const [selectAll, setSelectAll] = useState(false)
    const [showRes, setShowRes] = useState(false)

    let documents = [1, 2, 3, 4, 5, 6]

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const viewDocument = () => {
        setViewDocName('Document')
        setDocModal(true)
    }

    const handleOk = () => {
        setDocModal(false);
        setViewDocName('')
    };
    const handleCancel = () => {
        setDocModal(false);
        setViewDocName('')
    };

    const onSelectAll = (e) => {
        setSelectAll(e.target.checked)
    }

    const openDropdown = (index) => {
        setActiveDropdown(index)
        setActiveChild(0)
    }

    const openResolutions = (index) => {
        setActiveChild(index)
        setShowRes(true)
    }

    const closeResolutions = (index) => {
        setActiveChild(index)
        setShowRes(false)
    }

    const DownloadDoc = () => {
        downloadFile(SampleDoc, 'sample_doc.pdf')
        // downloading()
    }

    // async function downloading(params) {
    //     await axios({
    //         url: SampleDoc2,
    //         method: "GET",
    //         responseType: "blob", // important
    //         onDownloadProgress: (progressEvent) => {
    //             let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //             console.log(percentCompleted);
    //         },
    //     }).then(res => {
    //         const url = window.URL.createObjectURL(new Blob([res.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', 'file_test.pdf'); //or any other extension
    //         document.body.appendChild(link);
    //         link.click();
    //     })
    // }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-4 mb-md-5">
                <h1 className='page-title'>Storage</h1>
                <button className='primary-btn'>Export</button>
            </div>
            <div className="header-wrapper">
                <p>Entity Name:&nbsp;<b>Sample Entity</b></p>
                <b className='mx-1'>&nbsp; | &nbsp;</b>
                <p>Entity Type:&nbsp;<b>Foundation</b></p>
                <b className='mx-1'>&nbsp; | &nbsp;</b>
                <p>Entity Code:&nbsp;<b>E001</b></p>
                <b className='mx-1'>&nbsp; | &nbsp;</b>
                <p>RM Name:&nbsp;<b>John Doe</b></p>
                <b className='mx-1'>&nbsp; | &nbsp;</b>
                <p>RM Code:&nbsp;<b>R423</b></p>
            </div>
            <div className="row mt-4">
                <div className="col-md-3 mb-4 mb-md-0">
                    <ul className='custom-dropdown'>
                        <li>
                            <div className={`list-item ${activeDropdown == 0 ? 'active' : ''}`} onClick={() => openDropdown(0)}>
                                <p><i className="far fa-star"></i> Pre-Incorp</p>
                                <p className='d-flex align-items-center'><span>1</span> <i className={`far fa-chevron-down ${activeDropdown == 0 ? 'fa-rotate-180' : ''}`}></i></p>
                            </div>
                            <ul className={`child-dropdown ${activeDropdown == 0 ? 'show' : ''}`}>
                                <li className={activeChild == 0 ? 'active' : ''} onClick={() => closeResolutions(0)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Pre-Incorp</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={`list-item ${activeDropdown == 1 ? 'active' : ''}`} onClick={() => openDropdown(1)}>
                                <p><i className="far fa-heart-rate"></i> Due Diligence</p>
                                <p className='d-flex align-items-center'><span>4</span> <i className={`far fa-chevron-down ${activeDropdown == 1 ? 'fa-rotate-180' : ''}`}></i></p>
                            </div>
                            <ul className={`child-dropdown ${activeDropdown == 1 ? 'show' : ''}`}>
                                <li className={activeChild == 0 ? 'active' : ''} onClick={() => closeResolutions(0)}>
                                    <p><i className="fas fa-circle fa-xs"></i> AML Pack</p>
                                </li>
                                <li className={activeChild == 1 ? 'active' : ''} onClick={() => closeResolutions(1)}>
                                    <p><i className="fas fa-circle fa-xs"></i> STAT Pack</p>
                                </li>
                                <li className={activeChild == 2 ? 'active' : ''} onClick={() => closeResolutions(2)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Individual KYC</p>
                                </li>
                                <li className={activeChild == 3 ? 'active' : ''} onClick={() => closeResolutions(3)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Corporate KYC</p>
                                </li>
                                <li className={activeChild == 4 ? 'active' : ''} onClick={() => closeResolutions(4)}>
                                    <p><i className="fas fa-circle fa-xs"></i> RAF</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={`list-item ${activeDropdown == 2 ? 'active' : ''}`} onClick={() => {
                                setActiveDropdown(2)
                                setShowRes(true)
                            }}>
                                <p><i className="far fa-square"></i> Legal</p>
                                <p className='d-flex align-items-center'><span>4</span> <i className={`far fa-chevron-down ${activeDropdown == 2 ? 'fa-rotate-180' : ''}`}></i></p>
                            </div>
                            <ul className={`child-dropdown ${activeDropdown == 2 ? 'show' : ''}`}>
                                <li className={activeChild == 0 ? 'active' : ''} onClick={() => openResolutions(0)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Resolutions</p>
                                </li>
                                <li className={activeChild == 1 ? 'active' : ''} onClick={() => closeResolutions(1)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Statutory Documents</p>
                                </li>
                                <li className={activeChild == 2 ? 'active' : ''} onClick={() => closeResolutions(2)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Registers</p>
                                </li>
                                <li className={activeChild == 3 ? 'active' : ''} onClick={() => closeResolutions(3)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Incorporation Pack</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={`list-item ${activeDropdown == 3 ? 'active' : ''}`} onClick={() => openDropdown(3)}>
                                <p><i className="far fa-analytics"></i> Correspondance</p>
                                <p className='d-flex align-items-center'><span>2</span> <i className={`far fa-chevron-down ${activeDropdown == 3 ? 'fa-rotate-180' : ''}`}></i></p>
                            </div>
                            <ul className={`child-dropdown ${activeDropdown == 3 ? 'show' : ''}`}>
                                <li className={activeChild == 0 ? 'active' : ''} onClick={() => closeResolutions(0)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Email Correspondence</p>
                                </li>
                                <li className={activeChild == 1 ? 'active' : ''} onClick={() => closeResolutions(1)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Internal Communication</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={`list-item ${activeDropdown == 4 ? 'active' : ''}`} onClick={() => openDropdown(4)}>
                                <p><i className="far fa-history"></i> Miscellaneous</p>
                                <p className='d-flex align-items-center'><span>1</span> <i className={`far fa-chevron-down ${activeDropdown == 4 ? 'fa-rotate-180' : ''}`}></i></p>
                            </div>
                            <ul className={`child-dropdown ${activeDropdown == 4 ? 'show' : ''}`}>
                                <li className={activeChild == 0 ? 'active' : ''} onClick={() => closeResolutions(0)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Documents</p>
                                </li>
                                <li className={activeChild == 1 ? 'active' : ''} onClick={() => closeResolutions(1)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Drafts</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={`list-item ${activeDropdown == 5 ? 'active' : ''}`} onClick={() => openDropdown(5)}>
                                <p><i className="far fa-address-card"></i> Filings</p>
                                <p className='d-flex align-items-center'><span>2</span> <i className={`far fa-chevron-down ${activeDropdown == 5 ? 'fa-rotate-180' : ''}`}></i></p>
                            </div>
                            <ul className={`child-dropdown ${activeDropdown == 5 ? 'show' : ''}`}>
                                <li className={activeChild == 0 ? 'active' : ''} onClick={() => closeResolutions(0)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Economic Substance</p>
                                </li>
                                <li className={activeChild == 1 ? 'active' : ''} onClick={() => closeResolutions(1)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Boss Filing</p>
                                </li>
                                <li className={activeChild == 2 ? 'active' : ''} onClick={() => closeResolutions(2)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Annual Return</p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <div className={`list-item ${activeDropdown == 6 ? 'active' : ''}`} onClick={() => openDropdown(6)}>
                                <p><i className="far fa-star"></i> Actioned Documents</p>
                                <p className='d-flex align-items-center'><span>1</span> <i className={`far fa-chevron-down ${activeDropdown == 6 ? 'fa-rotate-180' : ''}`}></i></p>
                            </div>
                            <ul className={`child-dropdown ${activeDropdown == 6 ? 'show' : ''}`}>
                                <li className={activeChild == 0 ? 'active' : ''} onClick={() => closeResolutions(0)}>
                                    <p><i className="fas fa-circle fa-xs"></i> Translations</p>
                                </li>
                                <li className={activeChild == 1 ? 'active' : ''} onClick={() => closeResolutions(1)}>
                                    <p><i className="fas fa-circle fa-xs"></i> OCR Extracts</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="col-md-6">
                    {showRes ?
                        <div className="row">
                            <div className="d-flex gap-3 flex-column flex-md-row align-items-start align-items-md-center mb-4">
                                <Checkbox onChange={onSelectAll} className='custom-checkbox'>Select All</Checkbox>
                                <button className='outline-btn'><i className="far fa-arrow-to-bottom"></i> Download Selected</button>
                            </div>
                            {documents?.map((doc, i) => {
                                return (
                                    <div className="col-md-6 mb-4" key={i}>
                                        <Checkbox onChange={onChange} checked={selectAll} className='custom-checkbox mb-3'>Document 0{doc}</Checkbox>
                                        <div className="uploader">
                                            <div className="uploaded-file">
                                                <img className='file-icon' src={PDFIcon} alt="" />
                                                <div className='ms-2'>
                                                    <h6>sample_document.pdf</h6>
                                                    <p>200 KB</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <button className='view-btn' onClick={() => viewDocument(doc)}><i className="far fa-eye"></i> View</button>
                                                <button className='download-btn' onClick={DownloadDoc}><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div>
                            <div className='doc-upload-wrapper mb-4'>
                                <div className="doc-uploaded">
                                    <div className='d-flex align-items-center'>
                                        <img className='file-icon' src={PDFIcon} alt="" />
                                        <div className='ms-2'>
                                            <h6>Tech design requirements.pdf</h6>
                                            <p>200 KB – 100% uploaded</p>
                                        </div>
                                    </div>
                                    <i className="fas fa-check-circle upload-icon"></i>
                                </div>
                                <button className='doc-action' onClick={viewDocument}><i className="far fa-eye"></i></button>
                                <button className='doc-action' onClick={DownloadDoc}><i className="far fa-arrow-to-bottom"></i></button>
                            </div>
                            <div className='doc-upload-wrapper mb-4'>
                                <div className="doc-uploaded" style={{ backgroundSize: '70%' }}>
                                    <div className='d-flex align-items-center'>
                                        <img className='file-icon' src={DOCIcon} alt="" />
                                        <div className='ms-2'>
                                            <h6>Dashboard prototype.docx</h6>
                                            <p>16 MB – 70% uploaded</p>
                                        </div>
                                    </div>
                                    <i className="fas fa-check-circle upload-icon"></i>
                                </div>
                                <button className='doc-action' onClick={viewDocument}><i className="far fa-eye"></i></button>
                                <button className='doc-action' onClick={DownloadDoc}><i className="far fa-arrow-to-bottom"></i></button>
                            </div>
                            <div className='doc-upload-wrapper mb-4'>
                                <div className="doc-uploaded">
                                    <div className='d-flex align-items-center'>
                                        <img className='file-icon' src={PDFIcon} alt="" />
                                        <div className='ms-2'>
                                            <h6>Tech design requirements.pdf</h6>
                                            <p>200 KB – 100% uploaded</p>
                                        </div>
                                    </div>
                                    <i className="fas fa-check-circle upload-icon"></i>
                                </div>
                                <button className='doc-action' onClick={viewDocument}><i className="far fa-eye"></i></button>
                                <button className='doc-action' onClick={DownloadDoc}><i className="far fa-arrow-to-bottom"></i></button>
                            </div>
                            <div className='doc-upload-wrapper mb-4'>
                                <div className="doc-uploaded">
                                    <div className='d-flex align-items-center'>
                                        <img className='file-icon' src={PDFIcon} alt="" />
                                        <div className='ms-2'>
                                            <h6>Tech design requirements.pdf</h6>
                                            <p>200 KB – 100% uploaded</p>
                                        </div>
                                    </div>
                                    <i className="fas fa-check-circle upload-icon"></i>
                                </div>
                                <button className='doc-action' onClick={viewDocument}><i className="far fa-eye"></i></button>
                                <button className='doc-action' onClick={DownloadDoc}><i className="far fa-arrow-to-bottom"></i></button>
                            </div>
                            <div className='doc-upload-wrapper mb-4'>
                                <div className="doc-uploaded" style={{ backgroundSize: '70%' }}>
                                    <div className='d-flex align-items-center'>
                                        <img className='file-icon' src={DOCIcon} alt="" />
                                        <div className='ms-2'>
                                            <h6>Dashboard prototype.docx</h6>
                                            <p>16 MB – 70% uploaded</p>
                                        </div>
                                    </div>
                                    <i className="fas fa-check-circle upload-icon"></i>
                                </div>
                                <button className='doc-action' onClick={viewDocument}><i className="far fa-eye"></i></button>
                                <button className='doc-action' onClick={DownloadDoc}><i className="far fa-arrow-to-bottom"></i></button>
                            </div>
                            <div className='doc-upload-wrapper mb-4'>
                                <div className="doc-uploaded">
                                    <div className='d-flex align-items-center'>
                                        <img className='file-icon' src={PDFIcon} alt="" />
                                        <div className='ms-2'>
                                            <h6>Tech design requirements.pdf</h6>
                                            <p>200 KB – 100% uploaded</p>
                                        </div>
                                    </div>
                                    <i className="fas fa-check-circle upload-icon"></i>
                                </div>
                                <button className='doc-action' onClick={viewDocument}><i className="far fa-eye"></i></button>
                                <button className='doc-action' onClick={DownloadDoc}><i className="far fa-arrow-to-bottom"></i></button>
                            </div>
                        </div>
                    }
                </div>
                <div className="col-md-3">
                    <div className="summary-wrapper">
                        <h6>Summary</h6>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero repellat praesentium porro dolor minima cupiditate ipsa repudiandae perspiciatis repellendus. Commodi at eaque voluptatibus in, voluptatem esse consequuntur tempore autem nulla?</p>
                    </div>
                </div>
            </div>
            <Modal title={viewDocName} className='custom-modal' open={docModal} width={1000} onOk={handleOk} onCancel={handleCancel}>
                <div className="document-view">
                    <iframe src={SampleDoc} frameBorder="0" height="100%" width="100%"></iframe>
                </div>
            </Modal>
        </div>
    )
}
