import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { getCountry } from '../store/country/countrySlice';

const Jurisdiction = ((props) => {
    let { className, handleChange, value, disabled, placeholder } = props;

    const dispatch = useDispatch()
    const { data, isLoading } = useSelector((state) => state.country)
    const [country, setCountry] = useState([]);

    const getCountryOptions = () => {
        if (!data || data?.length == 0) {
            dispatch(getCountry())
        }
        let arr = []
        let obj = {}
        data?.map((option) => {
            obj['label'] = `${option?.name} - ${option?.code}`
            obj['value'] = option?.code
            arr.push(obj)
            obj = {}
        })
        setCountry(arr)
    }

    useEffect(() => {
        getCountryOptions()
    }, [data?.length])

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <Select
            showSearch
            filterOption={filterOption}
            className={`custom-select ${className}`}
            placeholder={placeholder || "Select jurisdiction"}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            loading={isLoading}
            options={country}
        />
    )
})

export default Jurisdiction
