import React, { useState } from 'react'

import InhouseTranslation from './components/inhouse-translation'
import ManualTranslation from './components/manual-translation'

export default function DocTranslation() {
    const [activeChild, setActiveChild] = useState(0)

    return (
        <div>
            <h1 className='page-title mb-5'>Document Translation</h1>
            <div className="row px-0">
                <div className="col-md-3">
                    <ul className='custom-dropdown child-first'>
                        <li>
                            <ul className={`child-dropdown show ps-0`}>
                                <li className={activeChild == 0 ? 'active' : ''} onClick={() => setActiveChild(0)}>
                                    <p><i className="fal fa-language"></i> Inhouse Translation</p>
                                </li>
                                <li className={activeChild == 1 ? 'active' : ''} onClick={() => setActiveChild(1)}>
                                    <p><i className="fal fa-file-signature"></i> Manual External Translation</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="col-md-9">
                    {activeChild == 0 &&
                        <InhouseTranslation />
                    }
                    {activeChild == 1 &&
                        <ManualTranslation />
                    }
                </div>
            </div>
        </div>
    )
}
