import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Checkbox, Popover, Modal } from 'antd'

import PDFIcon from '../../assets/img/pdf-icon.png'

import SelectLanguage from '../../components/Language'

export default function Review() {
    const directors = [1, 2, 3]
    const shareholders = [1, 2, 3]
    const ubos = [1, 2, 3]

    const [pendingView, setPendingView] = useState(false);
    const [pendingPDF, setPendingPDF] = useState({
        Directors: [],
        Shareholders: [],
        UBOs: [],
        Statutory_Documents: [],
        Reserve_Director_Appointments: [],
        Corporate_KYC: []
    });

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const columns = [
        {
            title: 'Service Type',
            dataIndex: 'serviceType',
            sorter: (a, b) => a.serviceType - b.serviceType,
        },
        {
            title: 'Requestor ID',
            dataIndex: 'reqID',
            sorter: (a, b) => a.reqID - b.reqID,
        },
        {
            title: 'Entity Name',
            dataIndex: 'entityName',
            sorter: (a, b) => a.entityName - b.entityName,
        },
        {
            title: 'Entity Code',
            dataIndex: 'entityCode',
            sorter: (a, b) => a.entityCode - b.entityCode,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            sorter: (a, b) => a.clientName - b.clientName,
        },
        {
            title: 'Client Code',
            dataIndex: 'clientCode',
            sorter: (a, b) => a.clientCode - b.clientCode,
        },
        {
            title: 'Request Date',
            dataIndex: 'date',
            sorter: (a, b) => a.date - b.date,
        },
        {
            title: 'Assignee',
            dataIndex: 'assignee',
            sorter: (a, b) => a.assignee - b.assignee,
        },
    ];

    const data = [
        {
            key: '1',
            id: '1',
            serviceType: 'OCR',
            reqID: '2346',
            entityName: 'Trust',
            entityCode: 'CE34',
            clientName: 'Sample',
            clientCode: '8022',
            date: '20/10/2023',
            assignee: 'OCR',
        },
    ];

    const LanguageSelect = (
        <div>
            <SelectLanguage className="mw-300" />
            <button className='primary-btn mt-2'>Submit</button>
        </div>
    );

    const getPending = (obj, pdf) => {
        let arr = { ...pendingPDF }
        console.log(arr['Directors']);
        arr[obj].push(pdf)
        setPendingPDF(arr)
    }

    const handleOk = () => {
        setPendingView(false);
    };

    const handleCancel = () => {
        setPendingView(false);
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
                <h1 className='page-title mb-3 mb-md-0'>Onboarding Team Review Download: Step 1</h1>
                <div className="d-flex flex-column flex-md-row gap-3">
                    <button className='outline-btn' onClick={() => setPendingView(true)}>Request Pending Information</button>
                    <Link to={'/app/onboarding-team/upload'} className='primary-btn'>Complete Review and Submit</Link>
                </div>
            </div>
            {/* <h6 className='sub-title mb-4'>Assign</h6>
            <div className='mb-5'>
                <label htmlFor="" className="me-3 mb-1">Assign to</label>
                <div className="d-flex">
                    <Select
                        className="custom-select mw-300 mw-sm-65"
                        placeholder="Select"
                        onChange={handleChange}
                        options={[]}
                    />
                    <button className='primary-btn ms-3'>Submit</button>
                </div>
            </div> */}
            <Table className='custom-table' columns={columns} dataSource={data} pagination={false} />

            <div className='mt-4'>
                <h2 className='primary-title'>Directors</h2>
                {directors.map((director, i) => {
                    return (
                        <div className="col-md-9 my-4" key={i}>
                            <h6 className='sub-title mb-4'>Director 0{director}</h6>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Directors', 'Passport / ID')}>
                                        Passport / ID
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                        <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                    </Popover>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Directors', 'Address Proof')}>
                                        Address Proof
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Directors', 'Consent to Act')}>
                                        Consent to Act
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Directors', 'Resolution of Appointment')}>
                                        Resolution of Appointment
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Directors', 'Resolution of Resignation')}>
                                        Resolution of Resignation
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                <h2 className='primary-title'>Shareholders</h2>
                {shareholders.map((shareholder, i) => {
                    return (
                        <div className="col-md-9 my-4" key={i}>
                            <h6 className='sub-title mb-4'>Shareholder 0{shareholder}</h6>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Shareholders', 'Passport / ID')}>
                                        Passport / ID
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Shareholders', 'Address Proof')}>
                                        Address Proof
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Shareholders', 'Resolution of Share Allotment')}>
                                        Resolution of Share Allotment
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Shareholders', 'Resolution of Share Transfer')}>
                                        Resolution of Share Transfer
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Shareholders', 'Nominee Agreement')}>
                                        Nominee Agreement
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                <h2 className='primary-title'>UBOs</h2>
                {ubos.map((ubo, i) => {
                    return (
                        <div className="col-md-9 my-4" key={i}>
                            <h6 className='sub-title mb-4'>UBO 0{ubo}</h6>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('UBOs', 'Passport / ID')}>
                                        Passport / ID
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('UBOs', 'Address Proof')}>
                                        Address Proof
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('UBOs', 'Resolution of Shareholding')}>
                                        Resolution of Shareholding
                                    </Checkbox>
                                    <div className="uploader">
                                        <div className="uploaded-file">
                                            <img className='file-icon' src={PDFIcon} alt="" />
                                            <div className='ms-2'>
                                                <h6>Sample Document.pdf</h6>
                                                <p>200 KB</p>
                                            </div>
                                        </div>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                            </div>
                                            <div className="col-2">
                                                <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                                    <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                                </Popover>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                            </div>
                                            <div className="col-2">
                                                <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                <h2 className='primary-title'>Statutory Documents</h2>
                <div className="my-4">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Statutory_Documents', 'Client Information Sheet')}>
                                Client Information Sheet
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Statutory_Documents', 'Sample Document')}>
                                Sample Document
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Statutory_Documents', 'Appointment of First Director(s)')}>
                                Appointment of First Director(s)
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Statutory_Documents', 'Initial Share Allotment')}>
                                Initial Share Allotment
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Statutory_Documents', 'Corporate Seal')}>
                                Corporate Seal
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h2 className='primary-title'>Reserve Director Appointments</h2>
                <div className="my-4">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Reserve_Director_Appointments', 'Passport / ID')}>
                                Passport / ID
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Reserve_Director_Appointments', 'Address Proof')}>
                                Address Proof
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Reserve_Director_Appointments', 'Resolutions')}>
                                Resolutions
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h2 className='primary-title'>Corporate KYC</h2>
                <div className="my-4">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Corporate_KYC', 'Corporate KYC Pack 01')}>
                                Corporate KYC Pack 01
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Corporate_KYC', 'Corporate KYC Pack 02')}>
                                Corporate KYC Pack 02
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <Checkbox className='custom-checkbox mb-3' onChange={() => getPending('Corporate_KYC', 'Corporate KYC Pack 03')}>
                                Corporate KYC Pack 03
                            </Checkbox>
                            <div className="uploader">
                                <div className="uploaded-file">
                                    <img className='file-icon' src={PDFIcon} alt="" />
                                    <div className='ms-2'>
                                        <h6>Sample Document.pdf</h6>
                                        <p>200 KB</p>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-6">
                                        <button className='view-btn w-100'><i className="far fa-eye"></i> View</button>
                                    </div>
                                    <div className="col-2">
                                        <Popover content={LanguageSelect} trigger="click" className='custom-popover' title="Select Language">
                                            <button className='download-btn w-100' title='Translation'><i className="far fa-language"></i></button>
                                        </Popover>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn w-100' title='OCR Extraction'><i className="far fa-file-search"></i></button>
                                    </div>
                                    <div className="col-2">
                                        <button className='download-btn'><i className="far fa-arrow-to-bottom"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title={"Request Pending Information"} className='custom-modal' open={pendingView} width={600} onOk={handleOk} onCancel={handleCancel}>
                <div className='custom-form'>
                    {Object.entries(pendingPDF).map((p, i) => {
                        let keys = Object.keys(pendingPDF);
                        return (
                            <div key={i}>
                                {pendingPDF[keys[i]]?.length > 0 &&
                                    <div>
                                        <div className="primary-title mb-2">{keys[i].replaceAll('_', ' ')}</div>
                                        <div>
                                            {pendingPDF[keys[i]].map(n => {
                                                return (
                                                    <div className='mb-3' key={i}>
                                                        <label htmlFor="">{n}</label>
                                                        <input type="text" className='w-100' placeholder={`Enter ${n}`} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
            </Modal>
        </div>
    )
}
