import React from 'react'
import { Table } from 'antd';
import { Link } from 'react-router-dom';

export default function IncorporationFeedback() {

    const columns = [
        {
            title: 'Entity Name',
            dataIndex: 'entityName',
            sorter: (a, b) => a.entityName - b.entityName,
        },
        {
            title: 'Entity Type',
            dataIndex: 'entityType',
            sorter: (a, b) => a.entityType - b.entityType,
        },
        {
            title: 'Entity Code',
            dataIndex: 'entityCode',
            sorter: (a, b) => a.entityCode - b.entityCode,
        },
        {
            title: 'Incorp Date',
            dataIndex: 'incorpDate',
            sorter: (a, b) => a.incorpDate - b.incorpDate,
        },
        {
            title: 'Incorp Number',
            dataIndex: 'incorpNumber',
            sorter: (a, b) => a.incorpNumber - b.incorpNumber,
        },
        {
            title: 'Incorp Pack',
            dataIndex: 'incorpPack',
            render: (_, data) => (
                <div className="table-upload">
                    <label htmlFor="doc" className='outline-btn py-2'>Upload</label>
                    <input type="file" name="doc" id="doc" />
                </div>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            id: '1',
            entityName: 'Sample',
            entityType: 'Trust',
            entityCode: '9023',
            incorpDate: '12/04/2023',
            incorpNumber: '8237',
        },
        {
            key: '2',
            id: '2',
            entityName: 'Sample',
            entityType: 'Trust',
            entityCode: '9023',
            rmName: 'Sample',
            incorpDate: '14/04/2023',
            incorpNumber: '2342',
        },
        {
            key: '3',
            id: '3',
            entityName: 'Sample',
            entityType: 'Trust',
            entityCode: '9023',
            rmName: 'Sample',
            incorpDate: '16/04/2023',
            incorpNumber: '7324',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4 mb-md-5">
                <h1 className='page-title mb-3 mb-md-0'>Incorporation Feedback</h1>
                <Link to={'/app/onboarding-team/review-upload'} className='primary-btn'>Complete and Submit</Link>
            </div>
            <Table className='custom-table' rowSelection={{ type: 'checkbox', ...rowSelection }} columns={columns} dataSource={data} pagination={false} />
        </div>
    )
}
