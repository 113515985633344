import React, { useState, useEffect } from 'react'
import { Select, Table, Switch, Tag } from 'antd'
import { Link } from 'react-router-dom';

export default function ActiveRequests() {
    const [selectIncorp, setSelectIncorp] = useState('pre');
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        if (selectIncorp == 'pre') {
            setTableData({ col: preColumns, data: preData })
        }
        if (selectIncorp == 'post') {
            setTableData({ col: postColumns, data: postData })
        }
    }, [selectIncorp]);

    const getIncorpChange = (value) => {
        setSelectIncorp(value)
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const preColumns = [
        {
            title: 'Requestor Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (_, { status }) => (
                <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={status == 'Y' ? 'gold' : 'processing'}>
                    {status == 'Y' ? 'Pending' : 'In Progress'}
                </Tag>
            ),
        },
        {
            title: 'Request type',
            dataIndex: 'type',
            sorter: (a, b) => a.type - b.type,
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: (a, b) => a.createdDate - b.createdDate,
            // render: (text) => <span className='d-flex w-max ms-auto'>{text}</span>,
        },
        {
            title: 'Assignee 1',
            dataIndex: 'assignee1',
            sorter: (a, b) => a.assignee1 - b.assignee1,
            render: (_, { verified }) => (
                <Select
                    className="custom-select"
                    style={{ width: 100 }}
                    placeholder="Select Assignee"
                    defaultValue={'user1'}
                    options={[
                        {
                            value: 'user1',
                            label: 'User 1'
                        },
                        {
                            value: 'user2',
                            label: 'User 2'
                        },
                        {
                            value: 'user3',
                            label: 'User 3'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Assignee 2',
            dataIndex: 'assignee2',
            sorter: (a, b) => a.assignee2 - b.assignee2,
            render: (_, { verified }) => (
                <Select
                    className="custom-select"
                    style={{ width: 100 }}
                    placeholder="Select Assignee"
                    options={[
                        {
                            value: 'user1',
                            label: 'User 1'
                        },
                        {
                            value: 'user2',
                            label: 'User 2'
                        },
                        {
                            value: 'user3',
                            label: 'User 3'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, { id }) => (
                <div className="d-flex justify-content-end align-items-center gap-2">
                    <button className='action-btn'>Assign</button>
                    <button className='action-btn'>Re-assign</button>
                    <Link to={`/app/onboarding-team/download`} className='action-btn'>View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i></Link>
                </div>
            ),
        },
    ];
    const postColumns = [
        {
            title: 'Requestor Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Request type',
            dataIndex: 'type',
            sorter: (a, b) => a.type - b.type,
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
            render: (_, { status }) => (
                <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={status == 'Y' ? 'gold' : 'processing'}>
                    {status == 'Y' ? 'Pending' : 'In Progress'}
                </Tag>
            ),
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            sorter: (a, b) => a.createdDate - b.createdDate,
            // render: (text) => <span className='d-flex w-max ms-auto'>{text}</span>,
        },
        {
            title: 'Entity Name',
            dataIndex: 'entityName',
            key: 'entityName',
            sorter: (a, b) => a.entityName - b.entityName,
        },
        {
            title: 'Entity Code',
            dataIndex: 'entityCode',
            key: 'entityCode',
            sorter: (a, b) => a.entityCode - b.entityCode,
        },
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            sorter: (a, b) => a.verified - b.verified,
            render: (_, { verified }) => (
                <div className="d-flex gap-2">
                    <Switch checked={verified} className='custom-switch' checkedChildren="Yes" unCheckedChildren="No" />
                </div>
            ),
        },
        {
            title: 'Assignee 1',
            dataIndex: 'assignee1',
            sorter: (a, b) => a.assignee1 - b.assignee1,
            render: (_, { verified }) => (
                <Select
                    className="custom-select"
                    style={{ width: 100 }}
                    placeholder="Select Assignee"
                    defaultValue={'user1'}
                    options={[
                        {
                            value: 'user1',
                            label: 'User 1'
                        },
                        {
                            value: 'user2',
                            label: 'User 2'
                        },
                        {
                            value: 'user3',
                            label: 'User 3'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Assignee 2',
            dataIndex: 'assignee2',
            sorter: (a, b) => a.assignee2 - b.assignee2,
            render: (_, { verified }) => (
                <Select
                    className="custom-select"
                    style={{ width: 100 }}
                    placeholder="Select Assignee"
                    options={[
                        {
                            value: 'user1',
                            label: 'User 1'
                        },
                        {
                            value: 'user2',
                            label: 'User 2'
                        },
                        {
                            value: 'user3',
                            label: 'User 3'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, { id }) => (
                <div className="d-flex justify-content-end align-items-center gap-2">
                    <button className='action-btn'>Assign</button>
                    <button className='action-btn'>Re-assign</button>
                    <Link to={`/app/onboarding-team/download`} className='action-btn'>View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i></Link>
                </div>
            ),
        },
    ];

    const preData = [
        {
            key: '1',
            id: '1',
            name: 'Request #1',
            type: 'Sample',
            status: 'Y',
            createdDate: '08/10/2023 02:33PM',
        },
        {
            key: '2',
            id: '2',
            name: 'Request #2',
            type: 'Sample',
            status: 'Y',
            createdDate: '03/11/2023 04:31PM',
        },
        {
            key: '3',
            id: '3',
            name: 'Request #3',
            type: 'Sample',
            status: 'N',
            createdDate: '10/11/2023 06:11PM',
        },
    ];
    const postData = [
        {
            key: '1',
            id: '1',
            name: 'Request #1',
            type: 'Sample',
            status: 'N',
            createdDate: '07/11/2023 04:56PM',
            entityName: 'Trust',
            entityCode: 'C783',
            verified: true
        },
        {
            key: '2',
            id: '2',
            name: 'Request #2',
            type: 'Sample',
            status: 'N',
            createdDate: '06/11/2023 06:08PM',
            entityName: 'Trust',
            entityCode: 'C783',
            verified: false
        },
        {
            key: '3',
            id: '3',
            name: 'Request #3',
            type: 'Sample',
            status: 'Y',
            createdDate: '06/11/2023 02:23PM',
            entityName: 'Trust',
            entityCode: 'C783',
            verified: true
        },
        {
            key: '4',
            id: '4',
            name: 'Request #4',
            type: 'Sample',
            status: 'N',
            createdDate: '08/11/2023 06:08PM',
            entityName: 'Trust',
            entityCode: 'C783',
            verified: false
        },
        {
            key: '5',
            id: '5',
            name: 'Request #5',
            type: 'Sample',
            status: 'Y',
            createdDate: '10/11/2023 02:23PM',
            entityName: 'Trust',
            entityCode: 'C783',
            verified: false
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
                <h1 className='page-title mb-3 mb-md-0'>Active Requests</h1>
                <button className='primary-btn'>Submit</button>
            </div>
            <div className="custom-form mb-5 w-100">
                <div className="mb-4">
                    <label htmlFor="" className="me-3 mb-1">Select Request Type</label>
                    <div className="d-flex">
                        <Select
                            className="custom-select mw-300 mw-sm-65"
                            placeholder="Select incorp"
                            value={selectIncorp}
                            onChange={getIncorpChange}
                            options={[
                                {
                                    value: 'pre',
                                    label: 'Pre-Incorp'
                                },
                                {
                                    value: 'post',
                                    label: 'Post-Incorp'
                                },
                            ]}
                        />
                        <button className='primary-btn ms-3'>Submit</button>
                    </div>
                </div>
                <div>
                    <label htmlFor="" className="me-3 mb-1">Assign to</label>
                    <div className="d-flex">
                        <Select
                            className="custom-select mw-300 mw-sm-65"
                            placeholder="Select"
                            onChange={handleChange}
                            options={[]}
                        />
                        <button className='primary-btn ms-3'>Submit</button>
                    </div>
                </div>
            </div>

            <div>
                <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
                    <h4 className='primary-title text-capitalize mb-3 mb-md-0'>{selectIncorp}-Incorp Requests (03)</h4>
                    <div className="d-flex flex-column flex-md-row align-items-start gap-3">
                        <button className='outline-btn'><i className="far fa-arrow-to-bottom"></i> Download Selected</button>
                        <button className='outline-btn'><i className="far fa-check-double"></i> Assign Selected</button>
                    </div>
                </div>
                <Table
                    className='custom-table'
                    rowSelection={{ type: 'checkbox', ...rowSelection }}
                    columns={tableData.col}
                    dataSource={tableData.data}
                />
            </div>
        </div>
    )
}
