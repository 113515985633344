import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Select, DatePicker } from 'antd'
import Jurisdiction from '../../components/Jurisdiction'
import dayjs from 'dayjs'

export default function EntityOverview() {
    const [reminderCount, setReminderCount] = useState([0])
    const [historyShow, setHistoryShow] = useState([])
    const [uboHistory, setUboHistory] = useState(false)

    const toggleHistory = (index) => {
        let arr = [...historyShow]
        let item = arr.findIndex(hs => hs === index)
        if (item !== -1) {
            arr.splice(item, 1);
        } else {
            arr.push(index)
        }
        setHistoryShow(arr)
    }

    let directors = [1, 2, 3]
    let shareholders = [1, 2, 3]
    let ubo = [1]

    const columns = [
        {
            title: 'Share Certificate Number',
            dataIndex: 'shareNumber',
            sorter: (a, b) => a.shareNumber - b.shareNumber,
        },
        {
            title: 'Number of Shares',
            dataIndex: 'noShares',
            sorter: (a, b) => a.noShares - b.noShares,
        },
        {
            title: <div className='d-flex align-items-center'>Issued Date&nbsp;<i className="fal fa-question-circle"></i></div>,
            dataIndex: 'issuedDate',
            sorter: (a, b) => a.issuedDate - b.issuedDate,
        },
        {
            title: <div className='d-flex align-items-center'>Transfer Date&nbsp;<i className="fal fa-question-circle"></i></div>,
            dataIndex: 'transferDate',
            sorter: (a, b) => a.transferDate - b.transferDate,
        },
        {
            title: 'Transfer Amount',
            dataIndex: 'transferAmount',
            sorter: (a, b) => a.transferAmount - b.transferAmount,
        },
        {
            title: 'Par value',
            dataIndex: 'parValue',
            sorter: (a, b) => a.parValue - b.parValue,
        },
        {
            title: '',
            render: (_, { id }) => (
                <div className="d-flex gap-2 align-items-center justify-content-end">
                    <button className='action-btn delete-btn'><i className="far fa-trash-alt"></i></button>
                    <button className='action-btn'><i className="far fa-pencil"></i></button>
                </div>
            ),
        },
    ];
    const data = [
        {
            key: '1',
            id: '1',
            shareNumber: 55,
            noShares: 100,
            issuedDate: '11-12-2023',
            transferDate: '12-12-2023',
            transferAmount: 500,
            parValue: 100
        },
        {
            key: '2',
            id: '2',
            shareNumber: 56,
            noShares: 500,
            issuedDate: '13-12-2023',
            transferDate: '14-12-2023',
            transferAmount: 800,
            parValue: 100
        },
        {
            key: '3',
            id: '3',
            shareNumber: 57,
            noShares: 250,
            issuedDate: '15-12-2023',
            transferDate: '17-12-2023',
            transferAmount: 400,
            parValue: 100
        },
        {
            key: '4',
            id: '4',
            shareNumber: 58,
            noShares: 120,
            issuedDate: '18-12-2023',
            transferDate: '19-12-2023',
            transferAmount: 1500,
            parValue: 100
        },
    ];

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const getReminderCount = (value) => {
        let arr = []

        for (var i = 0; i < value; i++) {
            arr.push(i);
        }
        setReminderCount(arr)
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4 mb-md-5">
                <h1 className='page-title mb-3 mb-md-0'>Entity Overview</h1>
                <Link to={'/app/entity-overview/list'} className='primary-btn'>Update</Link>
            </div>
            <div className="row">
                <div className="col-lg-9">
                    <h4 className='primary-title mb-4'>Entity</h4>
                    <div className="row custom-form mb-5">
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Sample Entity'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Type:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Trust'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Code:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'E001'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Status:</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select"
                                defaultValue={'active'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'active',
                                        label: 'Active'
                                    },
                                    {
                                        value: 'inactive',
                                        label: 'Inactive'
                                    },
                                    {
                                        value: 'closing',
                                        label: 'Closing'
                                    },
                                    {
                                        value: 'closed',
                                        label: 'Closed'
                                    },

                                    {
                                        value: 'transferringOut',
                                        label: 'Transferring Out'
                                    },
                                    {
                                        value: 'prospect',
                                        label: 'Prospect'
                                    },
                                    {
                                        value: 'transferringIn',
                                        label: 'Transferring In'
                                    },
                                    {
                                        value: 'liquidated',
                                        label: 'Liquidated'
                                    },
                                    {
                                        value: 'struckOff',
                                        label: 'Struck Off'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Client Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'John Doe'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Client Code:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'C040'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Client Status:</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select"
                                defaultValue={'Y'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'Y',
                                        label: 'Active'
                                    },
                                    {
                                        value: 'N',
                                        label: 'Inactive'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Parent Client Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'John Doe'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Parent Client Code:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'PC020'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Parent Client Status:</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select"
                                defaultValue={'Y'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'Y',
                                        label: 'Active'
                                    },
                                    {
                                        value: 'N',
                                        label: 'Inactive'
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Incorporation Number:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'04638274'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Jurisdiction:</label>
                            <Jurisdiction className="w-100" defaultValue={'IND'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Incorporated Date:</label>
                            <DatePicker
                                className="custom-datepicker w-100"
                                defaultValue={dayjs('22-12-2023', 'DD/MM/YYYY')}
                                placeholder="Enter Date (DD/MM/YYYY)"
                                format={'DD/MM/YYYY'}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Entity Grouping:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Random'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Related Entities:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'E002, E003'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Composite Risk Level:</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select risk level"
                                defaultValue={'high'}
                                onChange={handleChange}
                                options={[
                                    {
                                        value: 'low',
                                        label: 'Low'
                                    },
                                    {
                                        value: 'medium',
                                        label: 'Medium'
                                    },
                                    {
                                        value: 'high',
                                        label: 'High'
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <h4 className='primary-title mb-4'>Primary Contact</h4>
                    <div className="row custom-form mb-5">
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Primary Contact Name:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'John Doe'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Primary Contact Email:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'john@example.com'} />
                        </div>
                        <div className="col-md-4 mb-4">
                            <label htmlFor="">Primary Contact Mobile:</label>
                            <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'+94 77 123 4567'} />
                        </div>
                    </div>
                    <h4 className='primary-title mb-4'>Location of Registers</h4>
                    <div className="custom-form mb-5">
                        <div className="mb-4">
                            <label htmlFor="">Address Line 01</label>
                            <textarea name="" id="" className='w-100' defaultValue={'No. 32'} placeholder='Enter a description...' rows="5"></textarea>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="">Address Line 02</label>
                            <textarea name="" id="" className='w-100' defaultValue={'Sample Street'} placeholder='Enter a description...' rows="5"></textarea>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="">Address Line 03</label>
                            <textarea name="" id="" className='w-100' defaultValue={'Colombo 02, Sri Lanka.'} placeholder='Enter a description...' rows="5"></textarea>
                        </div>
                    </div>
                    <h4 className='primary-title mb-4'>Location of Financial Records</h4>
                    <div className="custom-form">
                        <div className="mb-4">
                            <label htmlFor="">Address Line 01</label>
                            <textarea name="" id="" className='w-100' defaultValue={'No. 32'} placeholder='Enter a description...' rows="5"></textarea>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="">Address Line 02</label>
                            <textarea name="" id="" className='w-100' defaultValue={'Sample Street'} placeholder='Enter a description...' rows="5"></textarea>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="">Address Line 03</label>
                            <textarea name="" id="" className='w-100' defaultValue={'Colombo 02, Sri Lanka.'} placeholder='Enter a description...' rows="5"></textarea>
                        </div>
                    </div>
                </div>
                <div className="row custom-form mb-5">
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Title</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select title"
                            defaultValue={'mr'}
                            // onChange={}
                            options={[
                                {
                                    value: 'mr',
                                    label: 'Mr'
                                },
                                {
                                    value: 'mrs',
                                    label: 'Mrs'
                                },
                                {
                                    value: 'miss',
                                    label: 'Miss'
                                },
                                {
                                    value: 'ms',
                                    label: 'Ms'
                                },
                                {
                                    value: 'dr',
                                    label: 'Dr'
                                },
                                {
                                    value: 'rev',
                                    label: 'Rev'
                                },
                            ]}
                        />
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Name of Financial Record Holder</label>
                        <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'John Doe'} />
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Email:</label>
                        <input type="text" className="w-100" placeholder="Enter Email" defaultValue={'john@example.com'} />
                    </div>
                    <div className="col-md-3 mb-4">
                        <label htmlFor="">Primary Contact Mobile:</label>
                        <input type="text" className="w-100" placeholder="Enter contact mobile" defaultValue={'+94 77 123 4567'} />
                    </div>
                </div>
                <h4 className='primary-title mb-4'>Directors</h4>
                {directors.map((a, i) => {
                    return (
                        <div key={i}>
                            <h3 className='sub-title mb-4'>Director 0{a} &nbsp;<i className="fal fa-question-circle"></i></h3>
                            <div className="row custom-form mb-4 mb-md-5">
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Salutation:</label>
                                    <Select
                                        className="custom-select w-100"
                                        placeholder="Select title"
                                        defaultValue={'mr'}
                                        // onChange={}
                                        options={[
                                            {
                                                value: 'mr',
                                                label: 'Mr'
                                            },
                                            {
                                                value: 'mrs',
                                                label: 'Mrs'
                                            },
                                            {
                                                value: 'miss',
                                                label: 'Miss'
                                            },
                                            {
                                                value: 'ms',
                                                label: 'Ms'
                                            },
                                            {
                                                value: 'dr',
                                                label: 'Dr'
                                            },
                                            {
                                                value: 'rev',
                                                label: 'Rev'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">First Name:</label>
                                    <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jacob'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Middle Name:</label>
                                    <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Smith'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Last Name:</label>
                                    <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jackson'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Birth:</label>
                                    <DatePicker
                                        className="custom-datepicker w-100"
                                        placeholder="Enter Date (DD/MM/YYYY)"
                                        defaultValue={dayjs('23-08-1986', 'DD/MM/YYYY')}
                                        format={'DD/MM/YYYY'}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Country of Birth: </label>
                                    <Jurisdiction className="w-100" placeholder="Select country of birth" defaultValue={'IND'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Nationality:</label>
                                    <Jurisdiction className="w-100" placeholder="Select nationality" defaultValue={'LKA'} />
                                </div>
                                <div className="col-md-3">

                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Director Code:</label>
                                    <input type="text" className="w-100" placeholder="Enter code" defaultValue={'D002'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Risk Level</label>
                                    <Select
                                        className="custom-select w-100"
                                        placeholder="Select risk level"
                                        defaultValue={'low'}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: 'low',
                                                label: 'Low'
                                            },
                                            {
                                                value: 'medium',
                                                label: 'Medium'
                                            },
                                            {
                                                value: 'high',
                                                label: 'High'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">PEP</label>
                                    <Select
                                        className="custom-select w-100"
                                        placeholder="Select"
                                        defaultValue={'Y'}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: 'Y',
                                                label: 'Yes'
                                            },
                                            {
                                                value: 'N',
                                                label: 'No'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Tax Info Number</label>
                                    <input type="text" className="w-100" placeholder="Enter tax info number" defaultValue={'04563218'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Passport Number:</label>
                                    <input type="text" className="w-100" placeholder="Enter passport number" defaultValue={'021545879654'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Expiry Date</label>
                                    <DatePicker
                                        className="custom-datepicker w-100"
                                        placeholder="Enter Date (DD/MM/YYYY)"
                                        defaultValue={dayjs('12-12-2028', 'DD/MM/YYYY')}
                                        format={'DD/MM/YYYY'}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Appointment: </label>
                                    <DatePicker
                                        className="custom-datepicker w-100"
                                        placeholder="Enter Date (DD/MM/YYYY)"
                                        defaultValue={dayjs('04-12-2022', 'DD/MM/YYYY')}
                                        format={'DD/MM/YYYY'}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Resignation: </label>
                                    <DatePicker
                                        className="custom-datepicker w-100"
                                        placeholder="Enter Date (DD/MM/YYYY)"
                                        defaultValue={dayjs('16-08-2023', 'DD/MM/YYYY')}
                                        format={'DD/MM/YYYY'}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })}
                <h4 className='primary-title mb-4'>Shareholders</h4>
                {shareholders.map((a, i) => {
                    return (
                        <div key={i} className='mb-4'>
                            <h3 className='sub-title mb-4'>Shareholder 0{a} &nbsp;<i className="fal fa-question-circle"></i></h3>
                            <div className="row custom-form">
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Salutation:</label>
                                    <Select
                                        className="custom-select w-100"
                                        placeholder="Select title"
                                        defaultValue={'mr'}
                                        // onChange={}
                                        options={[
                                            {
                                                value: 'mr',
                                                label: 'Mr'
                                            },
                                            {
                                                value: 'mrs',
                                                label: 'Mrs'
                                            },
                                            {
                                                value: 'miss',
                                                label: 'Miss'
                                            },
                                            {
                                                value: 'ms',
                                                label: 'Ms'
                                            },
                                            {
                                                value: 'dr',
                                                label: 'Dr'
                                            },
                                            {
                                                value: 'rev',
                                                label: 'Rev'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">First Name:</label>
                                    <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jacob'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Middle Name:</label>
                                    <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Smith'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Last Name:</label>
                                    <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jackson'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Birth:</label>
                                    <DatePicker
                                        className="custom-datepicker w-100"
                                        placeholder="Enter Date (DD/MM/YYYY)"
                                        defaultValue={dayjs('23-08-1986', 'DD/MM/YYYY')}
                                        format={'DD/MM/YYYY'}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Country of Birth: </label>
                                    <Jurisdiction className="w-100" placeholder="Select country of birth" defaultValue={'IND'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Nationality:</label>
                                    <Jurisdiction className="w-100" placeholder="Select nationality" defaultValue={'LKA'} />
                                </div>
                                <div className="col-md-3">

                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Passport Number:</label>
                                    <input type="text" className="w-100" placeholder="Enter passport number" defaultValue={'021545879654'} />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="">Expiry Date</label>
                                    <DatePicker
                                        className="custom-datepicker w-100"
                                        placeholder="Enter Date (DD/MM/YYYY)"
                                        defaultValue={dayjs('12-12-2028', 'DD/MM/YYYY')}
                                        format={'DD/MM/YYYY'}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">No. of Shares Held:</label>
                                    <input type="text" className="w-100" placeholder="Enter no. of shares held" defaultValue={'1000'} />
                                </div>
                                <div className="col-md-3 mb-4">

                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Shares Code:</label>
                                    <input type="text" className="w-100" placeholder="Enter code" defaultValue={'D002'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Risk Level</label>
                                    <Select
                                        className="custom-select w-100"
                                        placeholder="Select risk level"
                                        defaultValue={'low'}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: 'low',
                                                label: 'Low'
                                            },
                                            {
                                                value: 'medium',
                                                label: 'Medium'
                                            },
                                            {
                                                value: 'high',
                                                label: 'High'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">PEP</label>
                                    <Select
                                        className="custom-select w-100"
                                        placeholder="Select"
                                        defaultValue={'Y'}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: 'Y',
                                                label: 'Yes'
                                            },
                                            {
                                                value: 'N',
                                                label: 'No'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Tax Info Number</label>
                                    <input type="text" className="w-100" placeholder="Enter tax info number" defaultValue={'04563218'} />
                                </div>
                            </div>
                            <h6 className='hyper-link' onClick={() => toggleHistory(a)}>Detailed History <i className={`fas fa-chevron-down ${historyShow.findIndex(hs => hs === a) !== -1 ? 'fa-rotate-180' : ''}`}></i></h6>
                            {historyShow.findIndex(hs => hs === a) !== -1 ?
                                <Table className='custom-table-light mt-md-1' columns={columns} dataSource={data} pagination={false} />
                                :
                                <></>
                            }
                        </div>
                    )
                })}
                <h4 className='primary-title mb-4'>UBO</h4>
                {ubo.map((a, i) => {
                    return (
                        <div key={i} className='mb-4'>
                            <h3 className='sub-title mb-4'>UBO 0{a} &nbsp;<i className="fal fa-question-circle"></i></h3>
                            <div className="row custom-form">
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Salutation:</label>
                                    <Select
                                        className="custom-select w-100"
                                        placeholder="Select title"
                                        defaultValue={'mr'}
                                        // onChange={}
                                        options={[
                                            {
                                                value: 'mr',
                                                label: 'Mr'
                                            },
                                            {
                                                value: 'mrs',
                                                label: 'Mrs'
                                            },
                                            {
                                                value: 'miss',
                                                label: 'Miss'
                                            },
                                            {
                                                value: 'ms',
                                                label: 'Ms'
                                            },
                                            {
                                                value: 'dr',
                                                label: 'Dr'
                                            },
                                            {
                                                value: 'rev',
                                                label: 'Rev'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">First Name:</label>
                                    <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jacob'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Middle Name:</label>
                                    <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Smith'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Last Name:</label>
                                    <input type="text" className="w-100" placeholder="Enter Name" defaultValue={'Jackson'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Date of Birth:</label>
                                    <DatePicker
                                        className="custom-datepicker w-100"
                                        placeholder="Enter Date (DD/MM/YYYY)"
                                        defaultValue={dayjs('23-08-1986', 'DD/MM/YYYY')}
                                        format={'DD/MM/YYYY'}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Country of Birth: </label>
                                    <Jurisdiction className="w-100" placeholder="Select country of birth" defaultValue={'IND'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Nationality:</label>
                                    <Jurisdiction className="w-100" placeholder="Select nationality" defaultValue={'LKA'} />
                                </div>
                                <div className="col-md-3">

                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Passport Number:</label>
                                    <input type="text" className="w-100" placeholder="Enter passport number" defaultValue={'021545879654'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Expiry Date</label>
                                    <DatePicker
                                        className="custom-datepicker w-100"
                                        placeholder="Enter Date (DD/MM/YYYY)"
                                        defaultValue={dayjs('12-12-2028', 'DD/MM/YYYY')}
                                        format={'DD/MM/YYYY'}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Ownership Percentage:</label>
                                    <input type="text" className="w-100" placeholder="Enter ownership percentage" defaultValue={'49%'} />
                                </div>
                                <div className="col-md-3">

                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Total Shares Held:</label>
                                    <input type="text" className="w-100" placeholder="Enter total shares held" defaultValue={'1000'} />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Risk Level</label>
                                    <Select
                                        className="custom-select w-100"
                                        placeholder="Select risk level"
                                        defaultValue={'low'}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: 'low',
                                                label: 'Low'
                                            },
                                            {
                                                value: 'medium',
                                                label: 'Medium'
                                            },
                                            {
                                                value: 'high',
                                                label: 'High'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">PEP</label>
                                    <Select
                                        className="custom-select w-100"
                                        placeholder="Select"
                                        defaultValue={'Y'}
                                        onChange={handleChange}
                                        options={[
                                            {
                                                value: 'Y',
                                                label: 'Yes'
                                            },
                                            {
                                                value: 'N',
                                                label: 'No'
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="col-md-3 mb-4">
                                    <label htmlFor="">Tax Info Number</label>
                                    <input type="text" className="w-100" placeholder="Enter tax info number" defaultValue={'04563218'} />
                                </div>
                            </div>
                            <h6 className='hyper-link' onClick={() => setUboHistory(!uboHistory)}>Detailed History <i className={`fas fa-chevron-down ${uboHistory ? 'fa-rotate-180' : ''}`}></i></h6>
                            {uboHistory ?
                                <Table className='custom-table-light mt-md-1' columns={columns} dataSource={data} pagination={false} />
                                :
                                <></>
                            }
                        </div>
                    )
                })}
                <h4 className='primary-title mb-4'>CDD Information</h4>
                <div className="custom-form">
                    <div className='mb-4'>
                        <label htmlFor="" className="me-3">Source of Wealth</label>
                        <textarea name="" id="" className='w-100' placeholder='Enter a description...' rows="5"></textarea>
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="" className="me-3">Source of Funds</label>
                        <textarea name="" id="" className='w-100' placeholder='Enter a description...' rows="5"></textarea>
                    </div>
                    <div className='mb-4'>
                        <label htmlFor="" className="me-3">Purpose of Business</label>
                        <textarea name="" id="" className='w-100' placeholder='Enter a description...' rows="5"></textarea>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="me-3 mb-1">Geography of Business</label>
                                <Select
                                    className="custom-select w-100"
                                    placeholder="Select"
                                    onChange={handleChange}
                                    options={[]}
                                />
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="" className="me-3 mb-1">Business Activity</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select"
                                options={[]}
                            />
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="me-3 mb-1">Business Risk</label>
                                <Select
                                    className="custom-select w-100"
                                    placeholder="Select risk level"
                                    onChange={handleChange}
                                    options={[
                                        {
                                            value: 'low',
                                            label: 'Low'
                                        },
                                        {
                                            value: 'medium',
                                            label: 'Medium'
                                        },
                                        {
                                            value: 'high',
                                            label: 'High'
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="mb-1">Entity Last Reviewed Date</label>
                                <DatePicker
                                    className="custom-datepicker w-100"
                                    placeholder="Enter Date (DD/MM/YYYY)"
                                    format={'DD/MM/YYYY'}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="mb-1">Entity Next Review Date</label>
                                <input type="text" className='w-100' placeholder='Enter incorporation number' />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="mb-1">Overall Entity Risk Level</label>
                                <Select
                                    className="custom-select w-100"
                                    placeholder="Select risk level"
                                    onChange={handleChange}
                                    options={[
                                        {
                                            value: 'low',
                                            label: 'Low'
                                        },
                                        {
                                            value: 'medium',
                                            label: 'Medium'
                                        },
                                        {
                                            value: 'high',
                                            label: 'High'
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <label htmlFor="" className="me-3 mb-1">Reminder</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select"
                                defaultValue={1}
                                onChange={getReminderCount}
                                options={[
                                    {
                                        value: 1,
                                        label: 1,
                                    },
                                    {
                                        value: 2,
                                        label: 2,
                                    },
                                    {
                                        value: 3,
                                        label: 3,
                                    },
                                    {
                                        value: 4,
                                        label: 4,
                                    },
                                ]}
                            />
                        </div>
                        <div className="col-md-3">
                            <div className="mb-4">
                                <label htmlFor="" className="me-3 mb-1">Industry</label>
                                <Select
                                    className="custom-select w-100"
                                    placeholder="Select industry"
                                    onChange={handleChange}
                                    options={[]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4 mb-md-5">
                        {reminderCount.map((reminder, index) => {
                            return (
                                <div className="col-md-3 mb-4" key={index}>
                                    <label htmlFor="" className="mb-1">Reminder {reminder + 1} Date</label>
                                    <DatePicker
                                        className="custom-datepicker w-100"
                                        placeholder="Enter Date (DD/MM/YYYY)"
                                        format={'DD/MM/YYYY'}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
