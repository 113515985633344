import React from 'react'
import { Select } from 'antd';
import { Link } from 'react-router-dom';

import Jurisdiction from '../components/Jurisdiction';

export default function Incorporation() {

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
        <h1 className='page-title mb-3 mb-md-0'>New Incorporation Name Check</h1>
        <Link to={'/app/onboarding-team'} className='primary-btn'>Submit</Link>
      </div>
      <h2 className='sub-title mb-4'>Proposed Name</h2>
      <div className="custom-form">
        <div className="form-row mb-4">
          <label htmlFor="" className="me-3">Option 01</label>
          <input type="text" placeholder="Enter Name" />
        </div>
        <div className="form-row mb-4">
          <label htmlFor="" className="me-3">Option 02</label>
          <input type="text" placeholder="Enter Name" />
        </div>
        <div className="form-row mb-4">
          <label htmlFor="" className="me-3">Option 03</label>
          <input type="text" placeholder="Enter Name" />
        </div>
        <h2 className='sub-title mb-4'>Jurisdiction</h2>
        <div className="row">
          <div className="col-md-3 mb-4">
            <label htmlFor="" className="me-3 mb-1">Jurisdiction</label>
            <Jurisdiction className="w-100" value={'BVI'} disabled={true} handleChange={handleChange} />
          </div>
          <div className="col-md-3 mb-4">
            <label htmlFor="" className="me-3 mb-1">Entity Type</label>
            <Select
              className="custom-select w-100"
              placeholder="Select entity type"
              // onChange={}
              options={[
                {
                  value: 'bvibc',
                  label: 'BVIBC'
                },
                {
                  value: 'trust',
                  label: 'Trust'
                },
                {
                  value: 'generalPartnership',
                  label: 'General Partnership'
                },
                {
                  value: 'limitedPartnership',
                  label: 'Limited Partnership'
                },
                {
                  value: 'foundation',
                  label: 'Foundation'
                },
              ]}
            />
          </div>
        </div>
        {/* <h2 className='sub-title mb-4'>Relationship Manager</h2>
        <div className="d-flex gap-4 flex-column flex-md-row mb-4">
          <div>
            <label htmlFor="" className="me-3 mb-1">Relationship Manager Name</label>
            <input type="text" className='mw-300' placeholder="Enter Name" />
          </div>
          <div>
            <label htmlFor="" className="me-3 mb-1">Relationship Manager Code</label>
            <input type="text" className='mw-300' placeholder="Enter Code" />
          </div>
        </div> */}
      </div>
    </div>
  )
}
