// Auth
import Login from "../pages/auth/login";

//Private
import Home from "../pages/home";
import Dashboard from "../pages/dashboard";

import Incorporation from "../pages/incorporation";
import PostIncorp from "../pages/post-incorp";
import OnboardingTeamListing from "../pages/onboarding-team/listing";
import OnboardingTeamEdit from "../pages/onboarding-team/edit";
import OnboardingTeamDownload from "../pages/onboarding-team/download";
import OnboardingTeamUpload from "../pages/onboarding-team/upload";
import OnboardingTeamReviewList from "../pages/onboarding-team/review-listing";
import OnboardingTeamReviewUpload from "../pages/onboarding-team/review-upload";
import MasterFileCreation from "../pages/master-file/creation";
import PreIncorpResolution from "../pages/pre-incorp-resolution";
import RelationshipManager from "../pages/relationship-manager";
import ActiveRequests from "../pages/active-requests";
import MLRO from "../pages/mlro";
import NewIncorporation from "../pages/new-incorporation";
import IncorporationConfirmation from "../pages/incorporation-confirmation";
import IncorporationFeedback from "../pages/incorporation-feedback";
import EntityOverviewEdit from "../pages/entity-overview/edit";
import EntityOverviewList from "../pages/entity-overview/list";
import IncorporationPack from "../pages/incorporation-pack";

import AdditionalScreens from "../pages/additional-screens";
import CDD from "../pages/additional-screens/cdd";
import Client from "../pages/additional-screens/client";
import Shareholder from "../pages/additional-screens/shareholder";
import Director from "../pages/additional-screens/director";
import Crypto from "../pages/additional-screens/crypto";

import Storage from "../pages/storage";
import OcrExtraction from "../pages/ocr-extraction";
import DocTranslation from "../pages/doc-translation";
import ServiceInvoice from "../pages/service-invoice";
import AccessControl from "../pages/access-control";
import PendingRequests from "../pages/pending-requests";
import MasterSearch from "../pages/master-search";

import NotFound from "../pages/not-found";

const routes = [
  {
    name: "Login",
    path: "/",
    icon: "",
    component: <Login />,
    type: "public",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "Login",
    path: "/login",
    icon: "",
    component: <Login />,
    type: "public",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "Home",
    path: "home",
    icon: "fa-home",
    component: <Home />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    name: "Dashboard",
    path: "dashboard",
    icon: "fa-chart-bar",
    component: <Dashboard />,
    type: "private",
    permissions: ["admin"],
    showInMenu: true,
  },
  {
    name: "New Incorporation Request",
    path: "incorporation",
    component: <Incorporation />,
    icon: "fa-paper-plane",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    name: "Post Incorp Service Request",
    path: "post-incorp",
    component: <PostIncorp />,
    icon: "fa-plus-circle",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    path: "onboarding-team",
    component: <OnboardingTeamListing />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "onboarding-team/edit/:id",
    component: <OnboardingTeamEdit />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "Pre-Incorp Documentation",
    path: "onboarding-team/download",
    component: <OnboardingTeamDownload />,
    type: "private",
    icon: "fa-file-download",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    path: "onboarding-team/upload",
    component: <OnboardingTeamUpload />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "MLRO Approval",
    path: "onboarding-team/review",
    component: <OnboardingTeamReviewList />,
    icon: "fa-clipboard-check",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    path: "onboarding-team/review-upload",
    component: <OnboardingTeamReviewUpload />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "master-file-creation",
    component: <MasterFileCreation />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "pre-incorp-resolution",
    component: <PreIncorpResolution />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "relationship-manager",
    component: <RelationshipManager />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "Task/Request Queue",
    path: "active-requests",
    component: <ActiveRequests />,
    icon: "fa-tasks",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    path: "mlro",
    component: <MLRO />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "new-incorporation",
    component: <NewIncorporation />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "incorporation-confirmation",
    component: <IncorporationConfirmation />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "incorporation-feedback",
    component: <IncorporationFeedback />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "entity-overview/edit",
    component: <EntityOverviewEdit />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "entity-overview/list",
    component: <EntityOverviewList />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "incorporation-pack",
    component: <IncorporationPack />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "additional-screens",
    component: <AdditionalScreens />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "CDD",
    path: "additional-screens/cdd",
    component: <CDD />,
    icon: "fa-calendar-alt",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    path: "additional-screens/client",
    component: <Client />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "additional-screens/shareholder",
    component: <Shareholder />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    path: "additional-screens/director",
    component: <Director />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "Crypto TM",
    path: "additional-screens/crypto",
    component: <Crypto />,
    icon: "fa-usd-circle",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    path: "storage",
    component: <Storage />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "OCR Extraction",
    path: "ocr-extraction",
    component: <OcrExtraction />,
    icon: "fa-print-search",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    name: "Translation",
    path: "doc-translation",
    component: <DocTranslation />,
    icon: "fa-language",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    name: "Invoicing",
    path: "service-invoice",
    component: <ServiceInvoice />,
    icon: "fa-money-check-edit-alt",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    name: "Access Control",
    path: "access-control",
    component: <AccessControl />,
    icon: "fa-sliders-v",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    name: "Pending Request",
    path: "pending-requests",
    component: <PendingRequests />,
    icon: "fa-exclamation-circle",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    name: "VASP",
    path: "vasp",
    component: <Home />,
    icon: "fa-server",
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: true,
  },
  {
    path: "master-search",
    component: <MasterSearch />,
    type: "private",
    permissions: ["admin", "analyst", "client"],
    showInMenu: false,
  },
  {
    name: "Error 404",
    path: "*",
    icon: "",
    component: <NotFound />,
    type: "public",
    permissions: [],
    showInMenu: false,
  },
];

export default routes;
