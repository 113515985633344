import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'  
import { useSelector } from 'react-redux'

export default function Index() {
  const { isLogged, userDetails } = useSelector((state) => state.auth)

  if (isLogged) {
    return <Navigate to='/app/home' replace />;
    // if (userDetails?.role == 'employee') {
    //   return <Navigate to='/app/home' replace />;
    // }
    // if (userDetails?.role == 'admin'){
    //   return <Navigate to='/app/dashboard' replace />;
    // }
  }

  return (
    <div className='auth-layout'>
        <div className="auth-card">
          <Outlet />
          <p className='auth-copyright'>© Sentinel Pro 2024</p>
        </div>
        <div className="auth-bg">

        </div>
    </div>
  )
}
