import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Select, Input, DatePicker } from 'antd'
import Jurisdiction from '../../components/Jurisdiction';

const { Option } = Select

export default function Crypto() {
    const [transactionType, setTransactionType] = useState('usd')
    const [transactionSymbol, setTransactionSymbol] = useState('$')

    const getTransactionChanges = (value) => {
        let symbol = ''
        setTransactionType(value)
        if (value == 'usd') {
            symbol = '$'
        }
        if (value == 'inr') {
            symbol = '₹'
        }
        setTransactionSymbol(symbol)
    }


    const transactionSelect = (
        <Select defaultValue="usd" onChange={getTransactionChanges}>
            <Option value="usd">USD</Option>
            <Option value="inr">INR</Option>
        </Select>
    );

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4 mb-md-5">
                <h1 className='page-title mb-3 mv-md-0'>Crypto TM</h1>
                <Link to={'/app/incorporation-pack'} className='primary-btn'>Complete Assessment</Link>
            </div>
            <div className="custom-form">
                <h4 className='primary-title mb-4'>Director</h4>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Crypto Type</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select crypto Type"
                            // onChange={}
                            options={[]}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Origin</label>
                        <input type="text" className='w-100' placeholder="Enter origin" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Jurisdiction</label>
                        <Jurisdiction className={'w-100'} handleChange={handleChange} />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className="col-md-3">
                        <label htmlFor="" className="mb-1">Customer Risk Assessment</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select risk level"
                            defaultValue={'low'}
                            // onChange={handleChange}
                            options={[
                                {
                                    value: 'low',
                                    label: 'Low'
                                },
                                {
                                    value: 'medium',
                                    label: 'Medium'
                                },
                                {
                                    value: 'high',
                                    label: 'High'
                                },
                            ]}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="mb-1">Transaction Threshold</label>
                        <Input classNames={'custom-input'} addonBefore={transactionSymbol} suffix={<i className="fal fa-question-circle"></i>} addonAfter={transactionSelect} defaultValue="1,000.00" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">FinCEN Registration</label>
                        <input type="text" className='w-100' placeholder="Enter FinCEN registration" />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Crypto KYC</label>
                        <input type="text" className='w-100' placeholder="Enter crypto KYC" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">SSN</label>
                        <input type="text" className='w-100' placeholder="Enter SSN" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">FinCEN Number</label>
                        <input type="text" className='w-100' placeholder="Enter FinCEN number" />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className="col-md-3">
                        <label htmlFor="" className="mb-1">Buyer List</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select"
                            defaultValue={'low'}
                            // onChange={handleChange}
                            options={[]}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="mb-1">Seller List</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select"
                            defaultValue={'low'}
                            // onChange={handleChange}
                            options={[]}
                        />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="" className="mb-1">Overall Risk Level</label>
                        <Select
                            className="custom-select w-100"
                            placeholder="Select overall risk level"
                            defaultValue={'low'}
                            // onChange={handleChange}
                            options={[
                                {
                                    value: 'low',
                                    label: 'Low'
                                },
                                {
                                    value: 'medium',
                                    label: 'Medium'
                                },
                                {
                                    value: 'high',
                                    label: 'High'
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Legal Name</label>
                        <input type="text" className='w-100' placeholder="Enter name" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Birthdate</label>
                        <DatePicker
                            className="custom-datepicker w-100"
                            placeholder="Enter Date (DD/MM/YYYY)"
                            format={'DD/MM/YYYY'}
                        // onChange={}
                        />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">NIC Number</label>
                        <input type="text" className='w-100' placeholder="Enter NIC number" />
                    </div>
                </div>
                <div className="row mb-3 mb-md-4 gap-ms-2">
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Originator</label>
                        <input type="text" className='w-100' placeholder="Enter originator" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Beneficiary</label>
                        <input type="text" className='w-100' placeholder="Enter beneficiary" />
                    </div>
                    <div className='col-md-3'>
                        <label htmlFor="" className="mb-1">Address</label>
                        <input type="text" className='w-100' placeholder="Enter address" />
                    </div>
                </div>
                <div className="d-flex gap-4 my-4 my-md-5 flex-column flex-md-row">
                    <button className='outline-btn w-sm-100 justify-content-center'>Report Suspicious Activity</button>
                    <button className='primary-btn w-sm-100 justify-content-center'>Complete Onboarding</button>
                </div>
            </div>
        </div>
    )
}
