import React, { useState } from 'react'
import { Table, Modal, Select } from 'antd';

import SampleDoc from '../../../assets/pdf/sample.pdf'

export default function InhouseTranslation() {
    const [docModal, setDocModal] = useState(false)
    const [viewDocName, setViewDocName] = useState('')

    const columns = [
        {
            title: 'Document Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Document Type',
            dataIndex: 'type',
            sorter: (a, b) => a.type - b.type,
        },
        // {
        //     title: 'Entity Name',
        //     dataIndex: 'entityName',
        //     sorter: (a, b) => a.entityName - b.entityName,
        // },
        // {
        //     title: 'Entity Code',
        //     dataIndex: 'entityCode',
        //     sorter: (a, b) => a.entityCode - b.entityCode,
        // },
        {
            title: 'Assignee',
            dataIndex: 'assignee',
            sorter: (a, b) => a.assignee - b.assignee,
            render: (_, { verified }) => (
                <Select
                    className="custom-select"
                    style={{ width: 100 }}
                    placeholder="Select Assignee"
                    options={[
                        {
                            value: 'user1',
                            label: 'User 1'
                        },
                        {
                            value: 'user2',
                            label: 'User 2'
                        },
                        {
                            value: 'user3',
                            label: 'User 3'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Date & Time',
            dataIndex: 'dateTime',
            sorter: (a, b) => a.dateTime - b.dateTime,
        },
        {
            title: 'Actions',
            render: (_, { name }) => (
                <div className="d-flex gap-2 justify-content-end align-items-center">
                    <button className='action-btn'>Store</button>
                    <button className='transparent-btn' title='Preview' onClick={() => viewDocument(name || 'Document')}><i className="far fa-eye"></i></button>
                    <button className='transparent-btn' title='Export'><i className="far fa-file-upload"></i></button>
                    <button className='transparent-btn red-btn'><i className="far fa-trash-alt"></i></button>
                </div>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            id: '1',
            name: 'Document 1',
            type: 'Beneficiary',
            // entityName: 'Trust',
            // entityCode: 7322,
            dateTime: '12/04/2023 - 05:30PM'
        },
        {
            key: '2',
            id: '2',
            name: 'Document 2',
            type: 'Beneficiary',
            // entityName: 'Trust',
            // entityCode: 2362,
            dateTime: '12/04/2023 - 04:00PM'
        },
        {
            key: '3',
            id: '3',
            name: 'Document 3',
            type: 'Beneficiary',
            // entityName: 'Trust',
            // entityCode: 5653,
            dateTime: '12/04/2023 - 08:30PM'
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    const viewDocument = (docName) => {
        setViewDocName(docName)
        setDocModal(true)
    }

    const handleOk = () => {
        setDocModal(false);
        setViewDocName('')
    };
    const handleCancel = () => {
        setDocModal(false);
        setViewDocName('')
    };

    return (
        <div>
            <button className='primary-btn ms-auto'>Bulk Export</button>
            <Table className='custom-table' rowSelection={{ type: 'checkbox', ...rowSelection }} columns={columns} dataSource={data} />
            <Modal title={viewDocName} className='custom-modal' open={docModal} width={1000} onOk={handleOk} onCancel={handleCancel}>
                <div className="document-view">
                    <iframe src={SampleDoc} frameBorder="0" height="100%" width="100%"></iframe>
                </div>
            </Modal>
        </div>
    )
}