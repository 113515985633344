import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { notification } from "antd";
import axios from "axios";

import { store } from "../store/configureStore";
import { checkLogged } from "../store/auth/authSlice";

export const fetchApi = async (payload) => {
  const response = await axios(payload)
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      if (error?.response?.status === 403) {
        sendNotify("error", "Token was expired!");
        localStorage.removeItem(process.env.REACT_APP_JWT_TOKEN);
        store.dispatch(checkLogged());
      }
      return { error: error };
    });
  return response;
};

export const sendNotify = (type, content) => {
  notification.open({
    type: type,
    message: content,
  });
};

export const downloadFile = (path, filename) => {
  var link = document.createElement("a");
  link.href = path;
  link.download = filename;
  link.dispatchEvent(new MouseEvent("click"));
};

export const downloadBase64 = (base64String, filename) => {
  const bytes = atob(base64String);
  const arrayBuffer = new ArrayBuffer(bytes.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < bytes.length; i++) {
    uint8Array[i] = bytes.charCodeAt(i);
  }

  const workbook = XLSX.read(uint8Array, { type: "array" });
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([wbout], { type: "application/octet-stream" });

  saveAs(blob, filename);
};

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const downloadAsCSV = (apiData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  saveAs(data, fileName + fileExtension);
};

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url?.replace("-", "+").replace("_", "/");
  let data = getAtob(base64) ? getAtob(base64) : null;
  let details = {};
  details = { ...JSON.parse(data) };
  return details;
}

function getAtob(data) {
  try {
    return window.atob(data);
  } catch (e) {
    return false;
  }
}
