import React from 'react'

import Directors from './directors'
import Shareholders from './shareholders'
import UBO from './ubo'

export default function Individual() {
    return (
        <div>
            <Directors />
            <Shareholders />
            <UBO />
        </div>
    )
}
