import React, { useState } from 'react'

export default function UBO() {
    const [ubo, setUbo] = useState([1]);

    const addNewUbo = () => {
        let arr = [...ubo]
        arr.push(ubo.length + 1)
        setUbo(arr)
    }

    return (
        <div>
            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-md-5">
                <h2 className='primary-title mb-3 mb-md-0'>UBO</h2>
                <button className='primary-btn' onClick={addNewUbo}><i className="fal fa-plus"></i> Add New UBO</button>
            </div>
            {ubo.map((director, i) => {
                return (
                    <div className="custom-form form-bordered col-md-9 my-4" key={i}>
                        <h6 className='sub-title mb-4'>UBO 0{director}</h6>
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <label htmlFor="passport-id" className="me-3">Passport / ID</label>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="passport-id" id="passport-id" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <label htmlFor="address-proof" className="me-3">Address Proof</label>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="address-proof" id="address-proof" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <label htmlFor="resolution-of-appointment" className="me-3">UBO source of funds document</label>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="resolution-of-appointment" id="resolution-of-appointment" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
