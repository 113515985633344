import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import Logo from '../../assets/img/logo.png'
import FormZ from '../../components/FormZ'
import { sendNotify, fetchApi } from '../../helper'
import { checkLogged } from '../../store/auth/authSlice'

export default function Login() {
  const dispatch = useDispatch()
  const formRef = useRef();

  const [loading, setLoading] = useState(false)

  let formSchema = [
    {
      name: 'email',
      value: '',
      type: 'text',
      label: 'Username',
      placeholder: 'Enter your username',
      required: true,
    },
    {
      name: 'password',
      value: '',
      type: 'password',
      label: 'Password',
      validation: false,
      placeholder: 'Enter your password',
      required: true,
    },
  ]

  const login = () => {
    let valid = formRef.current.validFrom()
    if (valid) {
      setLoading(true)
      let obj = formRef.current.getPayload();
      let payload = {
        method: 'post',
        url: '/auth/login',
        data: obj
      }
      fetchApi(payload).then(response => {
        if (response) {
          setLoading(false)
          if (response.error) {
            sendNotify('error', response.error?.response?.data?.message)
          } else {
            sendNotify('success', 'Login successfully')
            localStorage.setItem(process.env.REACT_APP_JWT_TOKEN, JSON.stringify(response?.data))
            dispatch(checkLogged())
            formRef.current.clearForm()
          }
        }
      })
        .catch(error => ({ error: JSON.stringify(error) }));
    } else {
      sendNotify('error', 'Some fields are missing!')
    }
  }

  return (
    <div className='auth-form'>
      <img src={Logo} className='logo' alt="NL - Sentinel Pro" />
      <h2>Log in</h2>
      <p>Welcome back! Please enter your details.</p>
      <FormZ formSchema={formSchema} ref={formRef} onKeyPress={login} />
      <Link to="" className='auth-link'>Forgot password?</Link>
      <button className={`primary-btn auth-btn ${loading ? 'btn-loading' : ''}`} onClick={login}>
        {loading ? <i className="far fa-spinner-third fa-spin"></i> : ''}
        Sign in
      </button>
      <p className='auth-footer'>Don’t have an account? <Link to={'/register'}>Sign up</Link></p>
    </div>
  )
}
