import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'antd'

export default function Review() {
    const directors = [1, 2, 3]
    const shareholders = [1, 2, 3]
    const ubos = [1, 2, 3]

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const columns = [
        {
            title: 'Service Type',
            dataIndex: 'serviceType',
            sorter: (a, b) => a.serviceType - b.serviceType,
        },
        {
            title: 'Requestor ID',
            dataIndex: 'reqID',
            sorter: (a, b) => a.reqID - b.reqID,
        },
        {
            title: 'Entity Name',
            dataIndex: 'entityName',
            sorter: (a, b) => a.entityName - b.entityName,
        },
        {
            title: 'Entity Code',
            dataIndex: 'entityCode',
            sorter: (a, b) => a.entityCode - b.entityCode,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            sorter: (a, b) => a.clientName - b.clientName,
        },
        {
            title: 'Client Code',
            dataIndex: 'clientCode',
            sorter: (a, b) => a.clientCode - b.clientCode,
        },
        {
            title: 'Request Date',
            dataIndex: 'date',
            sorter: (a, b) => a.date - b.date,
        },
        {
            title: 'Assignee',
            dataIndex: 'assignee',
            sorter: (a, b) => a.assignee - b.assignee,
        },
    ];

    const data = [
        {
            key: '1',
            id: '1',
            serviceType: 'OCR',
            reqID: '2346',
            entityName: 'Trust',
            entityCode: 'CE34',
            clientName: 'Sample',
            clientCode: '8022',
            date: '20/10/2023',
            assignee: 'OCR',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
                <h1 className='page-title mb-3 mb-md-0'>Send to Approval</h1>
                <div className="d-flex flex-column flex-md-row gap-3">
                    <button className='outline-btn'>Request Pending Information</button>
                    <Link to={'/app/onboarding-team/review'} className='primary-btn'>Complete Review and Submit</Link>
                </div>
            </div>
            {/* <h6 className='sub-title mb-4'>Assign</h6>
            <div className='mb-5'>
                <label htmlFor="" className="me-3 mb-1">Assign to</label>
                <div className="d-flex">
                    <Select
                        className="custom-select mw-300 mw-sm-65"
                        placeholder="Select"
                        onChange={handleChange}
                        options={[]}
                    />
                    <button className='primary-btn ms-3'>Submit</button>
                </div>
            </div> */}
            <Table className='custom-table' columns={columns} dataSource={data} pagination={false} />

            <div className='mt-4'>
                <h2 className='primary-title'>Directors</h2>
                {directors.map((director, i) => {
                    return (
                        <div className="custom-form col-md-9 my-4" key={i}>
                            <h6 className='sub-title mb-4'>Director 0{director}</h6>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="passport-id" className="me-3">Passport / ID</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="passport-id" id="passport-id" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="address-proof" className="me-3">Address Proof</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="address-proof" id="address-proof" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="resolution-of-appointment" className="me-3">Consent to Act</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="resolution-of-appointment" id="resolution-of-appointment" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mb-4">
                                    <label htmlFor="resolution-of-resignation" className="me-3">Resolution of Appointment</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="consent-to-act-as-a-director" className="me-3">Resolution of Resignation</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="consent-to-act-as-a-director" className="me-3">Other</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                <h2 className='primary-title'>Shareholders</h2>
                {shareholders.map((shareholder, i) => {
                    return (
                        <div className="custom-form col-md-9 my-4" key={i}>
                            <h6 className='sub-title mb-4'>Shareholder 0{shareholder}</h6>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="passport-id" className="me-3">Passport / ID</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="passport-id" id="passport-id" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="address-proof" className="me-3">Address Proof</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="address-proof" id="address-proof" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="resolution-of-appointment" className="me-3">Resolution of Share Allotment</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="resolution-of-appointment" id="resolution-of-appointment" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 mb-4">
                                    <label htmlFor="resolution-of-resignation" className="me-3">Resolution of Share Transfer</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="consent-to-act-as-a-director" className="me-3">Nominee Agreement</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="consent-to-act-as-a-director" className="me-3">Other</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                <h2 className='primary-title'>UBOs</h2>
                {ubos.map((ubo, i) => {
                    return (
                        <div className="custom-form col-md-9 my-4" key={i}>
                            <h6 className='sub-title mb-4'>UBO 0{ubo}</h6>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="passport-id" className="me-3">Passport / ID</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="passport-id" id="passport-id" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="address-proof" className="me-3">Address Proof</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="address-proof" id="address-proof" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="resolution-of-resignation" className="me-3">Resolution of Shareholding</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <label htmlFor="consent-to-act-as-a-director" className="me-3">Other</label>
                                    <div className="uploader">
                                        <div className="upload-wrapper">
                                            <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                            <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                            <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                            <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div>
                <h2 className='primary-title'>Statutory Documents</h2>
                <div className="custom-form my-4">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <label htmlFor="passport-id" className="me-3">Client Information Sheet</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="passport-id" id="passport-id" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="address-proof" className="me-3">Inaugural Resolution</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="address-proof" id="address-proof" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="resolution-of-resignation" className="me-3">Appointment of First Director(s)</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="address-proof" className="me-3">Initial Share Allotment</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="address-proof" id="address-proof" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="resolution-of-resignation" className="me-3">Corporate Seal</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="consent-to-act-as-a-director" className="me-3">Other</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h2 className='primary-title'>Reserve Director Appointments</h2>
                <div className="custom-form my-4">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <label htmlFor="passport-id" className="me-3">Passport / ID</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="passport-id" id="passport-id" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="address-proof" className="me-3">Address Proof</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="address-proof" id="address-proof" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="resolution-of-resignation" className="me-3">Resolutions</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="consent-to-act-as-a-director" className="me-3">Other</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h2 className='primary-title'>Corporate KYC</h2>
                <div className="custom-form my-4">
                    <div className="row">
                        <div className="col-md-3 mb-4">
                            <label htmlFor="passport-id" className="me-3">Corporate KYC Pack 01</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="passport-id" id="passport-id" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="address-proof" className="me-3">Corporate KYC Pack 02</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="address-proof" id="address-proof" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="resolution-of-resignation" className="me-3">Corporate KYC Pack 03</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="consent-to-act-as-a-director" className="me-3">Other</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
