import { useState } from 'react'
import { Link } from 'react-router-dom';
import { Table, Tag, Select, Modal, Checkbox } from 'antd';

export default function PendingRequests() {

    const [resubmitModal, setResubmitModal] = useState(false)

    const columns = [
        {
            title: 'Request Ref',
            dataIndex: 'ref',
            sorter: (a, b) => a.ref - b.ref,
        },
        {
            title: 'Request type',
            dataIndex: 'type',
            sorter: (a, b) => a.type - b.type,
        },
        {
            title: 'Request Status',
            dataIndex: 'status',
            sorter: (a, b) => a.status - b.status,
        },
        {
            title: 'Resubmission Status',
            dataIndex: 'resubmitStatus',
            sorter: (a, b) => a.resubmitStatus - b.resubmitStatus,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, { id }) => (
                // <div className="d-flex justify-content-end align-items-center gap-3">
                <button className='action-btn' onClick={() => setResubmitModal(true)}>Upload</button>
                //     <Link to={`/app/onboarding-team/download/${id}`} className='action-btn'>View Request &nbsp; <i className="far fa-long-arrow-right me-0"></i></Link>
                // </div>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            id: '1',
            ref: 'Request #1',
            type: 'Sample',
            status: <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={'gold'}>Pending</Tag>,
            resubmitStatus: <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={'gold'}>Pending</Tag>
        },
        {
            key: '2',
            id: '2',
            ref: 'Request #2',
            type: 'Sample',
            status: <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={'blue'}>In Progress</Tag>,
            resubmitStatus: <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={'blue'}>In Progress</Tag>
        },
        {
            key: '3',
            id: '3',
            ref: 'Request #3',
            type: 'Sample',
            status: <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={'green'}>Completed</Tag>,
            resubmitStatus: <Tag icon={<i className="fad fa-dot-circle me-1"></i>} color={'green'}>Resubmitted</Tag>
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-4">
                <h1 className='page-title mb-3 mb-md-0'>Pending Information</h1>
                <button className='primary-btn'>Resubmit</button>
            </div>
            <Table
                className='custom-table'
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                columns={columns}
                dataSource={data}
            />
            <Modal title={"Create Request"} className='custom-modal' open={resubmitModal} width={1000} onOk={() => setResubmitModal(false)} okText="Complete" onCancel={() => setResubmitModal(false)}>
                <div className="custom-form">
                    <div>
                        <label htmlFor="" className="me-3 mb-1">Request reference</label>
                        <input type="text" className='mw-300' placeholder="Enter req ref" />
                    </div>
                </div>
                <div className='mt-4'>
                    <h2 className='primary-title'>Directors</h2>
                    <div className="my-4">
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Passport / ID
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="passport-id" id="passport-id" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Address Proof
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="address-proof" id="address-proof" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Consent to Act
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="resolution-of-appointment" id="resolution-of-appointment" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Resolution of Appointment
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Resolution of Resignation
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Other
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className='primary-title'>Shareholders</h2>
                    <div className="my-4">
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Passport / ID
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="passport-id" id="passport-id" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Address Proof
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="address-proof" id="address-proof" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Consent to Act
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="resolution-of-appointment" id="resolution-of-appointment" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Resolution of Share Transfer
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Nominee Agreement
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Other
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className='primary-title'>UBOs</h2>
                    <div className="my-4">
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Passport / ID
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="passport-id" id="passport-id" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Address Proof
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="address-proof" id="address-proof" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Resolution of Shareholding
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="resolution-of-resignation" id="resolution-of-resignation" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-4">
                                <Checkbox className='custom-checkbox mb-3'>
                                    Other
                                </Checkbox>
                                <div className="uploader">
                                    <div className="upload-wrapper">
                                        <input type="file" name="consent-to-act-as-a-director" id="consent-to-act-as-a-director" />
                                        <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                        <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                        <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
