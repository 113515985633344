import React from 'react'
import { Outlet } from 'react-router-dom'
import axios from "axios";
import { FloatButton } from 'antd';

import Sidebar from './Sidebar'
import Navbar from './Navbar'
// import Footer from './Footer'

export default function Index() {
  let local = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN)
  let details = local ? JSON.parse(local) : null
  let token = details?.tokenObj?.accessToken

  axios.defaults.headers.common['Authorization'] = token;

  return (
    <div>
      <div className='base-layout'>
        <Sidebar />
        <div className='main'>
          {/* <Navbar /> */}
          <div className='page-content'>
            <Outlet />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
      <FloatButton.BackTop />
    </div>
  )
}
