import React from 'react'
import { Select } from 'antd';

export default function CorporateKYC() {

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9]

    return (
        <div className='custom-form'>
            <div className="mb-4">
                <label htmlFor="" className="me-3 mb-1">Role</label>
                <Select
                    className="custom-select mw-300"
                    placeholder="Select role"
                    onChange={handleChange}
                    options={[
                        {
                            value: 'director',
                            label: 'Director'
                        },
                        {
                            value: 'shareholder',
                            label: 'Shareholder'
                        },
                        {
                            value: 'ubo',
                            label: 'UBO'
                        },
                    ]}
                />
            </div>
            <h6 className='primary-title mb-4'>Corporate KYC (Compressed)</h6>
            <div className="info-box mb-4">
                <h6>Where the beneficial owner, director, shareholder or officer is a body corporate (company),
                    the following will need to be provided in support of the duly completed application form:</h6>
                <ol>
                    <li>
                        A certified or notarized copy of the Certificate of Incorporation or other equivalent governing
                        constitution
                    </li>
                    <li>A certified or notarized copy of the Memorandum and Articles of Association or equivalent
                        governing constitution</li>
                    <li>An authorized signatory listing (bearing specimen signatures)</li>
                    <li>An original Certificate of Good Standing</li>
                    <li>Certified or notarized copies of the Registers of Directors, Shareholders, Beneficial Owners
                        and Officers</li>
                    <li>A statement signed by the company’s director(s) explaining the current nature of the
                        company’s business and the usual source of the company’s funds (whether sales, loans,
                        owner’s own funds, other –including combination of sources.)</li>
                    <li>The following due diligence documentation on each director and each individual who owns
                        ten or more percent of the company:
                        <ul>
                            <li>A certified or notarized copy of the identification page of a valid passport (certification
                                wording to be the same as above).</li>
                            <li>A certified/notarized copy or an original of a utility bill, bank statement or credit/debit
                                card statement addressed to the respective individual at the mailing address given</li>
                            <li>An original professional (e.g. accountant or lawyer) or bank reference.</li>
                        </ul>
                    </li>
                </ol>
            </div>
            <div className="row">
                {arr.map(item => {
                    return (
                        <div className="col-md-3 mb-4">
                            <label htmlFor="passport-id" className="me-3">Corporate KYC Pack 0{item}</label>
                            <div className="uploader">
                                <div className="upload-wrapper">
                                    <input type="file" name="passport-id" id="passport-id" />
                                    <span className='icon'><i className="fal fa-cloud-upload"></i></span>
                                    <p><b>Click to upload</b> or drag and drop <br /> SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button className='view-btn'><i className="far fa-eye"></i> View</button>
                                    <button className='delete-btn'><i className="fal fa-trash-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
