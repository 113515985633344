import React from 'react'
import { Select } from 'antd'
import Jurisdiction from '../../../components/Jurisdiction';

export default function Client() {

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    return (
        <div className="custom-form">
            <h2 className='sub-title mb-4'>Master File Creation: Client</h2>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Client Unicode</label>
                    <input type="text" className='w-100' placeholder="Enter client unicode" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Client Name</label>
                    <input type="text" className='w-100' placeholder="Enter client name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Client Grouping</label>
                    <input type="text" className='w-100' placeholder="Enter client grouping" />
                </div>
            </div>
            <div className="row mb-3 mb-md-4 gap-ms-2">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Relationship Manager</label>
                    <input type="text" className='w-100' placeholder="Enter relationship manager name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Administrator</label>
                    <input type="text" className='w-100' placeholder="Enter administrator name" />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Admin Manager</label>
                    <input type="text" className='w-100' placeholder="Enter admin manager name" />
                </div>
            </div>
            <div className="row mb-4">
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Jurisdiction</label>
                    <Jurisdiction className={'w-100'} handleChange={handleChange} />
                </div>
                <div className='col-md-3'>
                    <label htmlFor="" className="me-3 mb-1">Service Fee Category</label>
                    <Select
                        className="custom-select w-100"
                        placeholder="Select service fee category"
                        // onChange={}
                        options={[]}
                    />
                </div>
            </div>
            {/* <div className="d-flex gap-3">
                <button className='primary-btn'>Analyze</button>
                <button className='outline-btn'>Web Search</button>
            </div> */}
        </div>
    )
}
