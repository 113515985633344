import React from 'react'
import { Link } from 'react-router-dom';
import { Table, Tag, Select, Modal, Checkbox } from 'antd';

export default function MasterSearch() {

    const columns = [
        {
            title: 'Entity Name',
            dataIndex: 'entityName',
            sorter: (a, b) => a.entityName - b.entityName,
        },
        {
            title: 'Entity Code',
            dataIndex: 'entityCode',
            sorter: (a, b) => a.entityCode - b.entityCode,
        },
        {
            title: 'Entity Type',
            dataIndex: 'entityType',
            sorter: (a, b) => a.entityType - b.entityType,
            // render: (_, { status }) => (
            //     <Select
            //         className="custom-select"
            //         placeholder="Select type"
            //         value={status}
            //         style={{
            //             width: 160
            //         }}
            //         options={[
            //             {
            //                 value: 'bvibc',
            //                 label: 'BVIBC'
            //             },
            //             {
            //                 value: 'trust',
            //                 label: 'Trust'
            //             },
            //             {
            //                 value: 'generalPartnership',
            //                 label: 'General Partnership'
            //             },
            //             {
            //                 value: 'limitedPartnership',
            //                 label: 'Limited Partnership'
            //             },
            //             {
            //                 value: 'foundation',
            //                 label: 'Foundation'
            //             },
            //         ]}
            //     />
            // ),
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            sorter: (a, b) => a.clientName - b.clientName,
        },
        {
            title: 'Client Code',
            dataIndex: 'clientCode',
            sorter: (a, b) => a.clientCode - b.clientCode,
        },
        {
            title: 'Parent Client Name',
            dataIndex: 'parentClientName',
            sorter: (a, b) => a.parentClientName - b.parentClientName,
        },
        {
            title: 'Parent Client Code',
            dataIndex: 'parentClientCode',
            sorter: (a, b) => a.parentClientCode - b.parentClientCode,
        },
        {
            title: 'Service Fee Category',
            dataIndex: 'serviceFeeCategory',
            sorter: (a, b) => a.serviceFeeCategory - b.serviceFeeCategory,
            width: 200
        },
        {
            title: 'Jurisdiction',
            dataIndex: 'jurisdiction',
            sorter: (a, b) => a.jurisdiction - b.jurisdiction,
        },
        {
            title: 'Incorp Date',
            dataIndex: 'incorpDate',
            sorter: (a, b) => a.incorpDate - b.incorpDate,
        },
        {
            title: 'Risk Status',
            dataIndex: 'riskStatus',
            sorter: (a, b) => a.riskStatus - b.riskStatus,
            render: (_, { status }) => (
                <Select
                    className="custom-select"
                    placeholder="Select type"
                    value={status}
                    style={{
                        width: 160
                    }}
                    options={[
                        {
                            value: 'low',
                            label: 'Low'
                        },
                        {
                            value: 'medium',
                            label: 'Medium'
                        },
                        {
                            value: 'high',
                            label: 'High'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Entity Status',
            dataIndex: 'entityStatus',
            sorter: (a, b) => a.entityStatus - b.entityStatus,
            render: (_, { status }) => (
                <Select
                    className="custom-select"
                    placeholder="Select Status"
                    value={status}
                    style={{
                        width: 160
                    }}
                    options={[
                        {
                            value: 'active',
                            label: 'Active'
                        },
                        {
                            value: 'inactive',
                            label: 'Inactive'
                        },
                        {
                            value: 'closing',
                            label: 'Closing'
                        },
                        {
                            value: 'closed',
                            label: 'Closed'
                        },

                        {
                            value: 'transferringOut',
                            label: 'Transferring Out'
                        },
                        {
                            value: 'prospect',
                            label: 'Prospect'
                        },
                        {
                            value: 'transferringIn',
                            label: 'Transferring In'
                        },
                        {
                            value: 'liquidated',
                            label: 'Liquidated'
                        },
                        {
                            value: 'struckOff',
                            label: 'Struck Off'
                        },
                    ]}
                />
            ),
        },
        {
            title: 'Entity Overview',
            dataIndex: 'entityOverview',
            fixed: 'right',
            width: 150,
            render: (_, { status }) => (
                <Link to={'/app/entity-overview/list'} className='action-btn'><i className="far fa-eye"></i> View</Link>
            ),
        }
    ];

    const data = [
        {
            key: '1',
            id: '1',
            entityName: 'Sample',
            entityType: 'Trust',
            entityCode: '9023',
            rmName: 'Sample',
            clientName: 'COI',
            clientCode: '839',
            parentClientName: 'Hari',
            parentClientCode: '632',
            serviceFeeCategory: 'OCR',
            jurisdiction: 'India',
            incorpDate: '23/04/2023'
        },
        {
            key: '2',
            id: '2',
            entityName: 'Sample',
            entityType: 'Trust',
            entityCode: '9023',
            rmName: 'Sample',
            clientName: 'COI',
            clientCode: '839',
            parentClientName: 'Hari',
            parentClientCode: '632',
            serviceFeeCategory: 'OCR',
            jurisdiction: 'India',
            incorpDate: '23/04/2023'
        },
        {
            key: '3',
            id: '3',
            entityName: 'Sample',
            entityType: 'Trust',
            entityCode: '9023',
            rmName: 'Sample',
            clientName: 'COI',
            clientCode: '839',
            parentClientName: 'Hari',
            parentClientCode: '632',
            serviceFeeCategory: 'OCR',
            jurisdiction: 'India',
            incorpDate: '23/04/2023'
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (
        <div>
            <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-5">
                <h1 className='page-title mb-3 mb-md-0'>Master Search</h1>
                <Link to={'/app/master-file-creation'} className='primary-btn'>Create Master File</Link>
            </div>
            <h6 className='primary-title mb-4'>Search Criteria</h6>
            <div className="custom-form mb-4">
                <div className="row">
                    <div className="col-md-2 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">&nbsp;</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select type"
                                options={[
                                    {
                                        value: 'client',
                                        label: 'Client'
                                    },
                                    {
                                        value: 'parent-client',
                                        label: 'Parent Client'
                                    },
                                    {
                                        value: 'entity',
                                        label: 'Entity'
                                    },
                                    {
                                        value: 'individual',
                                        label: 'Individual'
                                    },
                                    {
                                        value: 'type',
                                        label: 'Type'
                                    },
                                    {
                                        value: '',
                                        label: 'Blank'
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="col-md-2 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Name</label>
                            <input type="text" className='w-100' placeholder="Enter name" />
                        </div>
                    </div>
                    <div className="col-md-2 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Code</label>
                            <input type="text" className='w-100' placeholder="Enter code" />
                        </div>
                    </div>
                    <div className="col-md-2 mb-4">
                        <div>
                            <label htmlFor="" className="me-3 mb-1">Type</label>
                            <Select
                                className="custom-select w-100"
                                placeholder="Select type"
                                options={[
                                    {
                                        value: 'bvibc',
                                        label: 'BVIBC'
                                    },
                                    {
                                        value: 'trust',
                                        label: 'Trust'
                                    },
                                    {
                                        value: 'generalPartnership',
                                        label: 'General Partnership'
                                    },
                                    {
                                        value: 'limitedPartnership',
                                        label: 'Limited Partnership'
                                    },
                                    {
                                        value: 'foundation',
                                        label: 'Foundation'
                                    },
                                    {
                                        value: '',
                                        label: 'Blank'
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="col-md-2 mb-4 d-flex align-items-end">
                        <button className='primary-btn'>Search</button>
                    </div>
                </div>
            </div>
            <Table
                className='custom-table'
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                columns={columns}
                dataSource={data}
                scroll={{ x: 2500 }}
            />
        </div>
    )
}
